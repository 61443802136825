<template>
<v-container>
    <template class="text-center">
        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
          </v-alert>
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-key
                    </v-icon>
                    <span class="text-h6 font-weight-light">Users Access</span>
                </v-card-title>
                
                <template v-if="orgUsers!=null && orgUsers.length>0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            ID
                          </th>
                          <th class="text-left">
                            Access
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in orgUsers"
                          :key="item.id"
                        >
                          <td style="font-size:12px;">{{ item.name }}</td>
                          <td style="font-size:12px;">{{ item.id }}</td>
                          <td>
                            <v-btn
                              elevation="3"
                              small
                              x-small
                            >Anular</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                  
                </v-card>
                
                </v-container>
              </v-col>

              <!--<v-col cols="6">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-newspaper-variant-outline
                    </v-icon>
                    <span class="text-h6 font-weight-light">Templates</span>
                </v-card-title>
                
                <template v-if="orgMsgTemplates!=null && orgMsgTemplates.length>0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Template
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in orgMsgTemplates"
                          :key="item.id"
                        >
                          <td style="font-size:12px;">{{ item.message }}</td>
                          <td style="font-size:12px;"></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;" width="70%">
                            <v-text-field
                              v-model="newTemplate.message"
                              label="Add new template (max 150 chars)"
                              :counter="150"
                              required

                            ></v-text-field>
                          </td>
                          <td style="font-size:12px;">
                            <v-btn :disabled="newTemplate.message=='' || sendNewTemplate"
                              @click="createNewMessageTemplates"
                              elevation="3"
                              class="primary"
                            >Add</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  
                </template>
                <template v-else>
                  <div>
                    <p class="display">No templates created</p>
                  </div>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;" width="70%">
                            <v-text-field
                              v-model="newTemplate.message"
                              label="Add new template (max 150 chars)"
                              :counter="150"
                              required

                            ></v-text-field>
                          </td>
                          <td style="font-size:12px;">
                            <v-btn :disabled="newTemplate.message=='' || sendNewTemplate"
                              @click="createNewMessageTemplates"
                              elevation="3"
                              class="primary"
                            >Add</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                </template>
                
                  
                </v-card>
                
                </v-container>
              </v-col>-->
              
            </v-row>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
   name:'Configs',
    data() {
        return {
            clientId: '',
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            userID: '',
            userRoles:null,
            jsonOauthToken: {},
            orgUsersInDB:null,
            orgUsers:null,
            orgMsgTemplates:null,
            newTemplate:{
              message:''
            },
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            idOrgInDB:'',
            urlAPI: this.$urlAPI+'genesys_obtainusersorginDB.php',
            urlAPI2: this.$urlAPI+'genesys_obtainusersorg.php',
            urlAPI3: this.$urlAPI+'smsApp_configs_getMessagesTemplates.php',
            urlAPI4: this.$urlAPI+'smsApp_configs_createNewTemplate.php',
            valid:false,
            typeSelected:'',
            theApp:{
            },
            authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',
            sendNewTemplate:false,
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            initAuth: false
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{
        async obtainUsersOrgInDB(){
            let url = this.urlAPI+'?id_organization='+this.idOrgInDB
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgUsersInDB = response.data.data.data

                        }else{

                            this.orgUsersInDB = null
                        }
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.orgUsersInDB = null
                        console.error(error)

                  //this.auth = false

                })
        },

        validarRoles(){
          let i=0

          let validate= false

          for(i=0; i<this.userRoles.length; i++){
            if(this.userRoles[i].name == 'admin'){
              this.$emit('isAdmin', true)
              validate = true
              break
            }
          }

          if(validate == false){
            this.$router.push({ name: 'Error' })
          }
          
        },

        async obtainUsersOrg(){
            let url = this.urlAPI2+'?theT='+this.tokenGC
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        if(response.data.entities != null){
                            this.orgUsers = response.data.entities

                        }else{

                            this.orgUsers = null
                        }
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.orgUsers = null
                        console.error(error)

                  //this.auth = false

                })
        },

        async obtainOrgMessageTemplates(){
            let url = this.urlAPI3+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgMsgTemplates = response.data.data.data
                        }else{
                            this.orgMsgTemplates = null
                        }
                        
                    }).catch(error => {
                        this.orgMsgTemplates = null
                        console.error(error)

                })
        },

        async createNewMessageTemplates(){
            let url = this.urlAPI4
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            this.sendNewTemplate = true

            let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('message', this.newTemplate.message)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false

                      if(response.data.code == '01'){
                        console.log('New template created')
                        console.log(response)
                        this.obtainOrgMessageTemplates()
                      }else{
                        console.log('Template not created, try later.')
                        console.log(response.data.data.error)
                      }
                      

                    }).catch(error => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
        },

        
    },
    watch:{
      /*autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },*/
              
    },
        mounted(){

            let theAppLocal = null
            this.auth = false
        
            if(localStorage.getItem('esmtAppT160')==null){
                this.auth = false
            }else{
                theAppLocal = JSON.parse(localStorage.getItem('esmtAppT160'))

                /*if(theAppLocal.roles!=null && theAppLocal.u!=null){
                    this.$router.push({ name: 'App' })
                }else{*/
                    this.theApp = theAppLocal.client
                    this.clientId = theAppLocal.client.id
                    this.clientSecret = theAppLocal.client.secret
                    this.tokenGC = theAppLocal.t
                    this.userID = theAppLocal.u
                    this.userRoles = theAppLocal.roles
                    this.idOrgInDB = theAppLocal.client.id

                    this.validarRoles()

                    this.obtainUsersOrgInDB()
                    this.obtainUsersOrg()
                    this.obtainOrgMessageTemplates()
                //}

                

              /*if(theAppLocal.t!=null){
                
              }*/
                

                //this.autoTokenGenerate = true
/*
                //this.auth = true

                //this.$router.push({ name: 'App' })*/
            }

            //console.log(theAppLocal)

        }
    
}
</script>
<style scoped>

</style>