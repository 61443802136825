<template>
    <v-container class="text-center">
        <h2 class="h2" style="color:#FFF;">Acceso no disponible</h2>
        <v-btn class="warning" @click="redo">
          Regresar
        </v-btn>
    </v-container>
</template>
<script>
export default {
    name:'noAccess',
    methods:{
        redo(){
            this.$router.push({ name: 'App' })
        }
    },
    data() {
        return {
            
        }
    },
    watch:{
    
              
    },
    mounted(){
        
    }
}
</script>