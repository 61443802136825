<template>
<v-container>
    <template class="text-center">
        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
          </v-alert>
          <v-container class="text-center" style="max-width:500px;">
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              
            >
            <v-card-title>
                <v-icon
                  large
                  left
                >
                  mdi-key
                </v-icon>
                <span class="text-h6 font-weight-light">User Access</span>
            </v-card-title>
            
            <div>
              <v-text-field
                v-model="userID"
                label="Paste Genesys Cloud (Admin) USER ID"
                required
              ></v-text-field>
            </div>

              <!--<v-text-field
                v-model="tokenGC"
                label="Token Auth (Bearer)"
                required
              ></v-text-field>

              <v-btn v-if="tokenGC==''" :disabled="(clientId=='' || secretClient=='')"
                color="blue"
                class="mr-4"
                @click="authToken"
              >
                Crear Token Auth
              </v-btn>-->
              
              <v-btn  :disabled="initAuth"
                color="success"
                class="mr-4"
                x-small
                @click="obtainUserRoles"
              >
                Acceder como administrador
              </v-btn>

              <!--<v-btn  :disabled="initAuth"
                color="success"
                class="mr-4"
                x-small
                @click="obtainUserRoles"
              >
                Acceso Standar
              </v-btn>-->

              <v-btn  :disabled="initAuth"
                color="success"
                class="mr-4"
                x-small
                @click="omitUserRoles"
              >
                Acceso Estandar
              </v-btn>
              
            </v-form>
            </v-card>
            </v-container>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
   name:'UserID',
    data() {
        return {
            clientId: '',
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            userID: '',
            userRoles:[],
            jsonOauthToken: {},
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            valid:false,
            typeSelected:'',
            theApp:{
            },
            authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            initAuth: false
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{
        async obtainUserRoles(){
            let url = 'https://esmtgenesysauth.esmtcx.solutions/genesysobtainuserroles.php?id='+this.userID+'&theT='+this.tokenGC
            this.errorConsult = false
            this.errorConsultText =  ''
            this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        this.initAuth = false
                        //console.log(response)
                        if(response.data.roles != null){
                            this.userRoles = response.data.roles
                            console.log(this.userRoles)

                            this.validarRoles()
                        }else{
                            this.errorConsult =  true
                            this.errorConsultText = response.data.data.error
                            console.log(this.errorConsultText)
                        }
                        
                    }).catch(error => {
                        this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        },

        async omitUserRoles(){
            
              this.userRoles.push({'name':'guest'})
              console.log(this.userRoles)
              this.validarRoles()
                        
        },

        validarRoles(){

            if(this.userRoles.length>0){
                let theAppLocal = {}                      
                
                theAppLocal.client = this.theApp
                theAppLocal.t = this.tokenGC
                theAppLocal.state = this.theApp.state
                theAppLocal.roles = this.userRoles
                theAppLocal.u = this.userID
                localStorage.setItem('esmtAppT160', JSON.stringify(theAppLocal))

                this.$router.push({ name: 'App' })
                
                console.log('Usuario autorizado')
            }else{
                console.log('Usuario no autorizado')
            }
                
        }
    },
    watch:{
      /*autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },*/
              
    },
        mounted(){

            let theAppLocal = null
            this.auth = false
        
            if(localStorage.getItem('esmtAppT160')==null){
                this.auth = false
            }else{
                theAppLocal = JSON.parse(localStorage.getItem('esmtAppT160'))

                if(theAppLocal.roles!=null && theAppLocal.u!=null){
                    this.$router.push({ name: 'App' })
                }else{
                    this.theApp = theAppLocal.client
                    this.clientId = theAppLocal.client.id
                    this.clientSecret = theAppLocal.client.secret
                    this.tokenGC = theAppLocal.t
                }

                

              /*if(theAppLocal.t!=null){
                
              }*/
                

                //this.autoTokenGenerate = true
/*
                //this.auth = true

                //this.$router.push({ name: 'App' })*/
            }

            console.log(theAppLocal)

        }
    
}
</script>
<style scoped>

</style>