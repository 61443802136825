<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <AuthApp :tokenImplicit="tokenImplicit" :urlClientId="urlClientId" :urlSecretClient="urlSecretClient"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import AuthApp from '@/components/Auth2.vue'

export default {
  name: 'Auth',
  props:{
    tokenImplicit:null,
    urlClientId:null,
    urlSecretClient:null,
  },
  components: {
    AuthApp
  }
}
</script>
