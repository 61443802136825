<template>
  <div class="Auth">
    <TokenComp :tokenImplicit="tokenImplicit" msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TokenComp from '@/components/Token.vue'

export default {
  name: 'Token',
  props:{
    tokenImplicit:null
  },
  components: {
    TokenComp
  }
}
</script>
