<template>
<v-container>
    <template class="text-center">
        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
          </v-alert>
          <v-container class="text-center" style="max-width:500px;">
            <p class="small" style="color:#FFF;"><small>Versión 1. Update 1.8.0</small></p>
            <v-card  elevation="2"
            outlined class="pl-4 pr-4 pt-4 pb-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              
            >
            <v-card-title>
                <v-icon
                  large
                  left
                >
                  mdi-bank
                </v-icon>
                <span class="text-h6 font-weight-light">Auth Process</span>
            </v-card-title>
            <v-select
                v-model="authTypeSelected"
                :items="authType"
                name="authType"
                item-text="authType"
                filled
                label="Select type Auth Process"
              ></v-select>

            
            <div v-if="authTypeSelected=='ClientID/SecretClient Auth'">
              <v-text-field
                v-model="clientId"
                label="Client ID"
                required
              ></v-text-field>

              <v-text-field
                v-model="clientSecret"
                label="Secret Client"
                required
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                v-model="connectorURL"
                label="Paste ESMT Conn URL Connector"
                required
              ></v-text-field>
            </div>

              <!--<v-text-field
                v-model="tokenGC"
                label="Token Auth (Bearer)"
                required
              ></v-text-field>

              <v-btn v-if="tokenGC==''" :disabled="(clientId=='' || secretClient=='')"
                color="blue"
                class="mr-4"
                @click="authToken"
              >
                Crear Token Auth
              </v-btn>-->
              
              <v-btn  :disabled="(authTypeSelected=='ClientID/SecretClient Auth' && (clientId=='' || clientSecret=='')) || (authTypeSelected=='ESMT Conn URL Connector' && connectorURL=='')"
                color="success"
                class="mr-4"
                @click="obtainAuthToken"
              >
                Acceder
              </v-btn>
              
            </v-form>
            </v-card>
            </v-container>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
   name:'AuthApp2',
   props:{
     tokenImplicit:null,
     urlSecretClient:null,
     urlClientId:null
   },
    data() {
        return {
            clientId: '',
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            jsonOauthToken: {},
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            valid:false,
            typeSelected:'',
            theApp:{
                name:'',
                description: '',
                state: ''
            },
            authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{
      
        async obtainAuthToken(){

            //Base24ClientCredential
            
            let Base24 = Buffer.from(this.clientId+ ':' + this.clientSecret).toString('base64')

            let url = ''

            if(this.authTypeSelected=='ClientID/SecretClient Auth'){
              url = 'https://esmtgenesysauth.esmtcx.solutions/genesysobtaintoken.php?type=client_credentials&Base24ClientCredential='+Base24
            }else{
              url = this.connectorURL
            }

            this.errorConsult = false
                //
                await axios.get(url, {
                //await axios.get(, {
                    headers:{

                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        //console.log(response)

                        if(response.data.error == null){
                            
                            console.log('Token '+response.data.access_token+' generado exitosamente')
                            this.tokenGC = response.data.access_token

                            if(this.authTypeSelected=='ESMT Conn URL Connector'){
                              this.clientId=response.data.client_id
                            }

                            this.errorConsult = false
                            this.errorConsultText = ''
                            
                            this.authApp()
                        }else{
                            console.log(response.data.error)
                            this.errorConsult = true
                            this.errorConsultText = response.data.error_description
                            console.log(response.data.error_description)
                        }

                    }).catch(error => {
                        this.errorConsult = true
                        this.errorConsultText = error
                        console.error(error)

                  //this.auth = false

                })
        },

        async authApp(){
          
          
          ///
           await axios.get('https://esmtgenesysauth.esmtcx.solutions/genesysauth.php?theid='+this.clientId+'&theT='+this.tokenGC, {
              headers:{
                /*'Authorization': 'Bearer '+this.tokenGC, 
                'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
              }
            }).then(response => {
                  console.log('//Org info')
                  console.log(response)

                  let genesysClientInfo = {}

                  genesysClientInfo.client = response.data
                  genesysClientInfo.t = this.tokenGC

                  localStorage.setItem('esmtAppT160', JSON.stringify(genesysClientInfo))

                  this.theApp.name = response.data.name
                  this.theApp.description = response.data.description
                  this.theApp.state = response.data.state

                  this.auth = true

                  this.$router.push({ name: 'UserID' })
                  //this.$router.push({ name: 'App' })

                }).catch(error => {
                    console.error(error)

                  this.auth = false

                })
        }
    },
    watch:{
      autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },
              
    },
        mounted(){

            let theAppLocal = null
            this.auth = false
          

            if((localStorage.getItem('esmtAppT160')==null || localStorage.getItem('esmtAppT160')=='null' ||  localStorage.getItem('esmtAppT160')=='')){
                this.auth = false
                if(this.tokenImplicit!=null){
                  this.tokenGC = this.tokenImplicit
                  this.authApp()
                  return false
                }

                
                if((this.urlSecretClient!=null) && (this.urlClientId!=null)){
                  this.clientId = this.urlClientId
                  this.clientSecret = this.urlSecretClient
                  this.obtainAuthToken()
                }

        
            }else{
              theAppLocal = JSON.parse(localStorage.getItem('esmtAppT160'))

              this.theApp.name = theAppLocal.name
              this.theApp.description = theAppLocal.description
              this.theApp.state = theAppLocal.state

              this.clientId = theAppLocal.client.id
              this.clientSecret = theAppLocal.client.secret

              /*if(theAppLocal.t!=null){
                
              }*/
                

                this.autoTokenGenerate = true
/*
                //this.auth = true

                //this.$router.push({ name: 'App' })*/
            }

            console.log(theAppLocal)

        }
    
}
</script>
<style scoped>

</style>