<template>
  <div class="Auth">
    <ErrorAccess msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorAccess from '@/components/ErrorAccess.vue'

export default {
  name: 'Token',

  components: {
    ErrorAccess
  }
}
</script>
