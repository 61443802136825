<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <ReportsComp/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import ReportsComp from '@/components/Reports.vue'

export default {
  name: 'Reports',
  components: {
    ReportsComp
  }
}
</script>
