<template>
<v-container>
    <template class="text-center">
        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
          </v-alert>
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-newspaper-variant-outline
                    </v-icon>
                    <span class="text-h6 font-weight-light">All Campaigns</span>
                </v-card-title>
                
                <template v-if="orgCampaigns!=null && orgCampaigns.length>0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Campaigns
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in orgCampaigns"
                          :key="item.id"
                        >
                          <td style="font-size:12px;">{{ item.name }}</td>
                          <td style="font-size:12px;"><v-btn class="ma-2 red--text m-0 p-0" fab dense x-small  @click="deleteItem('campaign', k, item.id)"><v-icon>mdi-delete</v-icon></v-btn></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;" width="70%">
                            <v-text-field
                              v-model="newCampaig.message"
                              label="Add new campaign (max 150 chars)"
                              :counter="150"
                              required

                            ></v-text-field>
                          </td>
                          <td style="font-size:12px;">
                            <v-btn :disabled="newCampaig.message=='' || sendNewCampaign"
                              @click="createNewCampaign"
                              elevation="3"
                              class="primary"
                            >Add</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  
                </template>
                <template v-else>
                  <div>
                    <p class="display">No campaigns created</p>
                  </div>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;" width="70%">
                            <v-text-field
                              v-model="newCampaig.message"
                              label="Add new campaigns (max 150 chars)"
                              :counter="150"
                              required

                            ></v-text-field>
                          </td>
                          <td style="font-size:12px;">
                            <v-btn :disabled="newCampaig.message=='' || sendNewCampaign"
                              @click="createNewCampaign"
                              elevation="3"
                              class="primary"
                            >Add</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                </template>
                
                  
                </v-card>
                
                </v-container>
              </v-col>

              <v-col cols="12">
              <v-container>
                <v-card  elevation="2"
                outlined class="pl-4 pr-4 pt-4 pb-4">
                <v-card-title>
                    <v-icon
                      large
                      left
                    >
                      mdi-newspaper-variant-outline
                    </v-icon>
                    <span class="text-h6 font-weight-light">Templates</span>
                </v-card-title>
                
                <template v-if="orgMsgTemplates!=null && orgMsgTemplates.length>0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Template
                          </th>
                          <th class="text-left">
                            Campaign
                          </th>
                          <th class="text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(plantillas, k) in orgMsgTemplates"
                          :key="plantillas.id"
                        >
                          <td style="font-size:12px;" width="50%">{{ plantillas.message }}</td>
                          <td style="font-size:12px; width:250px;">
                            <!--return-object-->
                            <v-select
                              v-model="orgMsgTemplates[k].organizations_campaigns_id"
                              :items="orgCampaigns"
                              name="name"
                              item-text="name"
                              item-value="name"
                              dense
                              solo
                              label="Select Campaign"
                              @change="setCampaignAsignement"
                            ></v-select>
                          </td>
                          <td style="font-size:12px;">
                            <v-btn class="ma-2 red--text m-0 p-0" fab dense x-small @click="deleteItem('template', k, plantillas.id)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn class="ma-2 green--text m-0 p-0" fab dense x-small @click="updateTemplate('template', k, plantillas)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;" width="70%">
                            <v-text-field
                              v-model="newTemplate.message"
                              label="Add new template (max 150 chars)"
                              :counter="150"
                              required

                            ></v-text-field>
                          </td>
                          <td style="font-size:12px;">
                            <v-btn :disabled="newTemplate.message=='' || sendNewTemplate"
                              @click="createNewMessageTemplates"
                              elevation="3"
                              class="primary"
                            >Add</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  
                </template>
                <template v-else>
                  <div>
                    <p class="display">No templates created</p>
                  </div>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td style="font-size:12px;" width="70%">
                            <v-text-field
                              v-model="newTemplate.message"
                              label="Add new template (max 150 chars)"
                              :counter="150"
                              required

                            ></v-text-field>
                          </td>
                          
                          <td style="font-size:12px;">
                            <v-btn :disabled="newTemplate.message=='' || sendNewTemplate"
                              @click="createNewMessageTemplates"
                              elevation="3"
                              class="primary"
                            >Add</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                </template>
                
                  
                </v-card>
                
                </v-container>
              </v-col>
              
            </v-row>
    </template>
  </v-container>
  <!--<v-container v-else>
      <h2 class="text-center">App Autenticada</h2>
      <p>Ya puedes usar la App</p>
  </v-container>-->
</template>

<script>
import axios from 'axios'
export default {
    
   name:'Campaigns',
    data() {
        return {
            clientId: '',
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            userID: '',
            userRoles:null,
            jsonOauthToken: {},
            orgUsersInDB:null,
            orgUsers:null,
            orgCampaigns:null,
            orgMsgTemplates:null,
            newTemplate:{
              message:''
            },
            newCampaig:{
              message:''
            },
            message:{
                from: "",
                pre: "",
                to: "",
                body: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            idOrgInDB:'',
            urlAPI: this.$urlAPI+'genesys_obtainusersorginDB.php',
            urlAPI2: this.$urlAPI+'genesys_obtainusersorg.php',
            urlAPI3: this.$urlAPI+'campaign_get_all.php',
            urlAPI4: this.$urlAPI+'campaig_create_new.php',
            urlAPI5: this.$urlAPI+'smsApp_configs_getMessagesTemplates.php',
            urlAPI6: this.$urlAPI+'smsApp_configs_createNewTemplate.php',
            urlAPI7: this.$urlAPI+'deleteTemplateOrCampaign.php',
            urlAPI8: this.$urlAPI+'updateTemplateCampaign.php',
            valid:false,
            typeSelected:'',
            theApp:{
            },
            authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',
            sendNewTemplate:false,
            sendNewCampaign:false,
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            initAuth: false,
            selectedTemplateForEdit:''
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
    methods:{

      async updateTemplate(template, k, item){
        console.log(template)
        console.log(k)
        console.log(item)

        let url = this.urlAPI8;

          let data = new FormData();

            data.append('id', item.id)
            data.append('organization_id', item.organization_id)
            data.append('organizations_campaigns_id', item.organizations_campaigns_id)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {

                      if(response.data.code == '01'){
                        this.obtainOrgCampaigns()
                        this.obtainOrgMessageTemplates()
                      }
                      

                    }).catch(error => {
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
      },

      async setCampaignAsignement(e){
        console.log(e)
        //console.log(this.selectedTemplateForEdit)
       

        ///let url = this.urlAPI8;

        /*let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('organizations_campaigns_id', e.name)
            data.append('id', e.id)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {

                      if(response.data.code == '01'){
                        this.obtainOrgCampaigns()
                        this.obtainOrgMessageTemplates()
                      }
                      

                    }).catch(error => {
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })*/
      },

      async deleteItem(element, k, id){
        console.log(k)
        let url = this.urlAPI7;

        let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('type', element)
            data.append('id', id)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {

                      if(response.data.code == '01'){
                        this.obtainOrgCampaigns()
                        this.obtainOrgMessageTemplates()
                      }
                      

                    }).catch(error => {
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
      },

        async obtainUsersOrgInDB(){
            let url = this.urlAPI+'?id_organization='+this.idOrgInDB
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgUsersInDB = response.data.data.data

                        }else{

                            this.orgUsersInDB = null
                        }
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.orgUsersInDB = null
                        console.error(error)

                  //this.auth = false

                })
        },

        validarRoles(){
          let i=0

          let validate= false

          for(i=0; i<this.userRoles.length; i++){
            if(this.userRoles[i].name == 'admin'){
              this.$emit('isAdmin', true)
              validate = true
              break
            }
          }

          if(validate == false){
            this.$router.push({ name: 'Error' })
          }
          
        },

        async obtainUsersOrg(){
            let url = this.urlAPI2+'?theT='+this.tokenGC
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        if(response.data.entities != null){
                            this.orgUsers = response.data.entities

                        }else{

                            this.orgUsers = null
                        }
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.orgUsers = null
                        console.error(error)

                  //this.auth = false

                })
        },

        async obtainOrgCampaigns(){
            let url = this.urlAPI3+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgCampaigns = response.data.data.data
                        }else{
                            this.orgCampaigns = null
                        }
                        
                    }).catch(error => {
                        this.orgMsgTemplates = null
                        console.error(error)

                })
        },

        async createNewCampaign(){
            let url = this.urlAPI4
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            this.sendNewCampaign = true

            let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('name', this.newCampaig.message)
            data.append('description', this.newCampaig.message)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {
                      this.newCampaig.message = ''
                      this.sendNewCampaign = false

                      if(response.data.code == '01'){
                        console.log('New campaign created')
                        console.log(response)
                        this.obtainOrgCampaigns()
                      }else{
                        console.log('Campaign not created, try later.')
                        console.log(response.data.data.error)
                      }
                      

                    }).catch(error => {
                      this.newCampaig.message = ''
                      this.sendNewCampaign = false
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
        },

        async obtainOrgMessageTemplates(){
            let url = this.urlAPI5+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgMsgTemplates = response.data.data.data
                        }else{
                            this.orgMsgTemplates = null
                        }
                        
                    }).catch(error => {
                        this.orgMsgTemplates = null
                        console.error(error)

                })
        },

        async createNewMessageTemplates(){
            let url = this.urlAPI6
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            this.sendNewTemplate = true

            let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('message', this.newTemplate.message)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false

                      if(response.data.code == '01'){
                        console.log('New template created')
                        console.log(response)
                        this.obtainOrgMessageTemplates()
                      }else{
                        console.log('Template not created, try later.')
                        console.log(response.data.data.error)
                      }
                      

                    }).catch(error => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
        },

        
    },
    watch:{
      /*autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },*/
              
    },
        mounted(){

            let theAppLocal = null
            this.auth = false
        
            if(localStorage.getItem('esmtAppT160')==null){
                this.auth = false
            }else{
                theAppLocal = JSON.parse(localStorage.getItem('esmtAppT160'))

                /*if(theAppLocal.roles!=null && theAppLocal.u!=null){
                    this.$router.push({ name: 'App' })
                }else{*/
                    this.theApp = theAppLocal.client
                    this.clientId = theAppLocal.client.id
                    this.clientSecret = theAppLocal.client.secret
                    this.tokenGC = theAppLocal.t
                    this.userID = theAppLocal.u
                    this.userRoles = theAppLocal.roles
                    this.idOrgInDB = theAppLocal.client.id

                    //this.validarRoles()

                    //this.obtainUsersOrgInDB()
                    //this.obtainUsersOrg()
                    this.obtainOrgCampaigns()
                    this.obtainOrgMessageTemplates()
                //}

                

              /*if(theAppLocal.t!=null){
                
              }*/
                

                //this.autoTokenGenerate = true
/*
                //this.auth = true

                //this.$router.push({ name: 'App' })*/
            }

            //console.log(theAppLocal)

        }
    
}
</script>
<style scoped>

</style>