import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import UserID from '../views/UserID.vue'
import Configs from '../views/Configs.vue'
import Campaigns from '../views/Campaigns.vue'
import Reports from '../views/Reports.vue'
import App from '../views/Home.vue'
import Token from '../views/Token.vue'
import ErrorAccess from '../views/ErrorAccess.vue'


Vue.use(VueRouter)

//https://smsbrain.jakiodes.com/
//Vue.prototype.$urlAPI = 'https://smsbrain.jakiodes.com/'
Vue.prototype.$urlAPI = 'https://t160api.esmtcx.solutions/'

const routes = [
  {
    path: '/',
    name: 'Token',
    component: Token,
    props:true
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    props:true
  },
  {
    path: '/userid',
    name: 'UserID',
    component: UserID,
    props:true
  },
  {
    path: '/app',
    name: 'App',
    component: App,
    props:true
  },
  {
    path: '/configs',
    name: 'Configs',
    component: Configs,
    props:true
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    props:true
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    props:true
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorAccess,
    props:true
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
