<template>
  <v-container>
    <v-alert
        dense
        type="success"
        style="position:absolute; clear:both; z-index:999; right:0px;"
        v-if="sendedMessageOk"
        @click="closeMessageSendedOk()"
      >
        <strong>{{okSendMessage}}</strong>
      </v-alert>
      <v-alert
        dense
        type="warning"
        color="red"
        style="position:absolute; clear:both; z-index:999; right:0px;"
        v-if="sendedMessageError"
        @click="closeMessageSendedError()"
      >
        <strong>Error: </strong>{{errorSendMessage}}
      </v-alert>

      <v-container>
      <v-card
        class="mx-auto my-12 text-center p-4"
        style="width:95%;"
        outlined
      >
        <v-row>
          

        <v-col
            cols="12"
            md="6"
            sm="6"
          >
          <h2 class="mt-4">Select Date Range</h2>
          <date-range-picker
              ref="picker"
              :opens="center"
              :locale-data="{ firstDay: 1, format: 'YYYY-mm-dd' }"
              :minDate="minDate" :maxDate="maxDate"
              :singleDatePicker="singleDatePicker"
              :timePicker="timePicker"
              :timePicker24Hour="timePicker24Hour"
              :showWeekNumbers="showWeekNumbers"
              :showDropdowns="showDropdowns"
              :autoApply="autoApply"
              v-model="dateRange"
              :linkedCalendars="linkedCalendars"
              :disabled="consultCreated"
          >
          <template v-slot:input="picker" style="min-width: 350px;">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
          </date-range-picker>
        </v-col>

        <v-col
            cols="12"
            md="6"
            sm="6"
        >
          <h2 class="mt-4">Type Message</h2>
            <template>
              <v-container
                class="pt-0 flex-center mt-0"
                fluid
              >
                <v-radio-group row v-model="radioGroup">
                  <v-radio
                    v-for="n in radioOptionsTypeMessage"
                    :key="n"
                    :label="`${n}`"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>
        </v-col>
        <v-col
            cols="12"
            md="12"
            class="mb-4"
          >
          <v-btn
            class="mr-4 success"
            @click="obtainReport()"
            :disabled="consultCreated"
          >
            Consult
          </v-btn>
          <v-btn class="mr-4" @click="clear">
            Clean
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
      </v-container>
    
    <v-row v-if="smsRegs.length>0">
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      <h2 class="mt-4 text-center">{{chartDataPeriodReport.name}}</h2>
      <template>
        <Bar
          :chart-options="chartOptionsPeriodReport"
          :chart-data="chartDataPeriodReport"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </template>
      </v-card>
    </v-row>

    <v-row v-if="smsRegs.length>0 && radioGroup=='ALL'">
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      <h2 class="mt-4 text-center">{{chartDataPeriodReportINvOUT.name}}</h2>
      <template>
        <LineChartGenerator
          :chart-data="chartDataPeriodReportINvOUT"
          :char-options="chartOptionsPeriodReportINvOUT"
          :char-id="chartId2"
          :dataset-id-key="datasetIdKey2"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </template>
      </v-card>
    </v-row>
    <!-- -->

    <v-row v-if="smsRegs.length>0">
      <v-col cols="4">
        <v-card
          class="mx-auto my-6"
          style="width:95%;"
          outlined
        >
        <v-card-title class="m-4"><h2>Utilization</h2></v-card-title>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>Days Activity:</h3> <v-btn class="ml-2" variant="outlined">{{returnUtilizationDays()}}</v-btn> </v-card-actions>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>Days Consult:</h3> <v-btn class="ml-2" variant="outlined">{{returnUtilizationConsult()}}</v-btn></v-card-actions>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>Utilization (%):</h3> <v-btn class="ml-2" variant="outlined">{{returnUtilizationPercent(returnUtilizationDays(), returnUtilizationConsult())}}%</v-btn></v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          class="mx-auto my-6"
          style="width:95%;"
          outlined
        >
        <v-card-title class="m-4"><h2>Results</h2></v-card-title>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>Sended:</h3> <v-btn class="ml-2" variant="outlined">{{returnResultsSUM()}}</v-btn> </v-card-actions>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>Errors:</h3> <v-btn class="ml-2" variant="outlined">0</v-btn> </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          class="mx-auto my-6"
          style="width:95%;"
          outlined
        >
        <v-card-title class="m-4"><h2>Channels</h2></v-card-title>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>Whatsapp:</h3> <v-btn class="ml-2" variant="outlined">{{returnResultsSUM()}}</v-btn> </v-card-actions>
        <v-card-actions style="color:#555555;" class="ml-4"><h3>SMS:</h3> <v-btn class="ml-2" variant="outlined">0</v-btn> </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
    <v-row v-if="smsRegs.length>0">
      
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      
      <v-card-title class="m-4">Registers ({{smsRegs.length}}) <vue-json-to-csv :json-data="smsRegs" :csv-title="myTitle" ref="csv"><v-icon class="m-0 ml-auto">mdi-download</v-icon></vue-json-to-csv></v-card-title>

      <v-data-table style="width:100%; font-size:11px;"  :headers="headers" :search="search" :items="smsRegs" item-key="id">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr
              :class="[item.type=='IN' ? 'light-green lighten-3':'yellow accent-1']"
            >
              <td style="font-size:11px;">{{ item.type }}</td>
              <td style="font-size:11px;" width="20%">{{ item.user_id }}</td>
              <td style="font-size:11px;">{{ item.message_from }}</td>
              <td style="font-size:11px;">{{ item.message_to }}</td>
              <td style="font-size:11px;">{{ item.message.substring(0,30)+".." }}</td>
              <td style="font-size:11px;" :class="[item.status=='Failed' ? 'red lighten-1':'']">
                  {{ item.status }}
              </td>
              <td style="font-size:11px;">{{ item.record }}</td>
              <td style="font-size:11px;">{{item.mo_price}}</td>
              <td style="font-size:11px;">{{ item.created_at }}</td>
            </tr>
        </template>
      </v-data-table>
      </v-card>
    </v-row>
    <!-- -->
  </v-container>
</template>

<script>
import axios from 'axios'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

import { Bar, Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js'

import VueJsonToCsv from 'vue-json-to-csv'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement)

export default {
    
   name:'Reports',
   components:{
     DateRangePicker, Bar, LineChartGenerator, VueJsonToCsv
   },
   props: {
    chartId2: {
      type: String,
      default: 'line-chart'
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey2: {
      type: String,
      default: 'label'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 120
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
    data() {

        return {
          chartDataPeriodReport: {
            name:'',
            labels: [ 'January', 'February', 'March' ],
            datasets: [ { data: [200, 20, 12] } ]
          },
          chartOptionsPeriodReport: {
            responsive: true
          },

          chartDataPeriodReportINvOUT: {
            labels: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July'
            ],
            datasets: [
              {
                label: 'Data One',
                backgroundColor: '#f87979',
                data: [40, 39, 10, 40, 39, 80, 40]
              },
              {
                label: 'Data Two',
                backgroundColor: '#ffcc00',
                data: [120, 15, 39, 42, 105, 0, 22]
              }
            ]
          },
          chartOptionsPeriodReportINvOUT: {
            responsive: true,
            maintainAspectRatio: false
          },

          
            errorSendMessage:'',
            okSendMessage:'',
            sendedMessageOk:false,
            sendedMessageError:false,
            dateRange:{
              startDate: moment().format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD')
            },
            radioGroup: 'ALL',
            radioOptionsTypeMessage:[
              'ALL', 'IN', 'OUT'
            ],
            headers:[
              {'text': 'Type', 'value':'type'},
              {'text': 'UserID', 'value':'user_id'},
              {'text': 'Channel/From', 'value':'message_from'},
              {'text': 'To', 'value':'message_to'},
              {'text': 'Body', 'value':'message'},
              {'text': 'Status', 'value':'status'},
              {
                'text': 'ID', 
                'value':'record'
              },
              {
                'text': 'Price', 
                'value':'mo_price'
              },
              {'text': 'Created at', 'value':'created_at'},
            ],
            search:'',
            clientId: '',
            picker: null,
            consultCreated:false,
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            userID: '',
            userRoles:null,
            jsonOauthToken: {},
            orgUsersInDB:null,
            orgUsers:null,
            orgMsgTemplates:null,
            newTemplate:{
              message:''
            },
            smsRegs:[],
            range: {},
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            idOrgInDB:'',
            urlAPI: this.$urlAPI+'smsApp_getMessagesOrgReport.php',
            urlAPI2: this.$urlAPI+'genesys_obtainusersorg.php',
            urlAPI3: this.$urlAPI+'smsApp_configs_getMessagesTemplates.php',
            urlAPI4: this.$urlAPI+'smsApp_configs_createNewTemplate.php',
            valid:false,
            typeSelected:'',
            theApp:{
            },
            authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',
            sendNewTemplate:false,
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            initAuth: false,
            myTitle: 'ExportT160'
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
  filters: {
    dateCell (value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date (val) {
      return val ? val.toLocaleString() : ''
    }
  },
    methods:{
        /*onDateRangeChange(range) {
          this.range = range;
        },
        dateFormat (classes, date) {
          if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date()
          }
          return classes
        },*/
      returnUtilizationConsult(){
        let indata = this.chartDataPeriodReport.datasets[0].data
        let nonZeroNodes = indata.filter(node => node !== "0");
        let count = nonZeroNodes.length;
        return count
      },

      returnUtilizationDays(){
        //let indata = this.chartDataPeriodReport.datasets[0].data
        let outdata = this.chartDataPeriodReport.labels
        return outdata.length
      },

      returnUtilizationPercent(a, b){
        //let indata = this.chartDataPeriodReport.datasets[0].data
        return parseInt(((b*100)/a))
      },

      returnResultsSUM(){


        //return parseInt(sum)+parseInt(sum2)

        return this.smsRegs.length
      },

      exportToCsv(){
        this.$refs.csv.download()
      },

      closeMessageSendedOk(){
        this.sendedMessageOk = false
        this.okSendMessage =''
        console.log('Notification OK close')

      },
      closeMessageSendedError(){
        this.sendedMessageError = false
        this.errorSendMessage =''
        console.log('Notification error close')

        return false
      },

      clear(){
        this.smsRegs = []
        this.consultCreated = false
      },

        async obtainReport(){
            
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            
            let init = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            let end = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            console.log(init+', '+end)
            
            let url = this.urlAPI+'?id_organization='+this.idOrgInDB+'&init='+init+'&end='+end+'&type='+this.radioGroup

            this.consultCreated = true

            
            this.okSendMessage= ''
            this.sendedMessageOk= false
            
            this.sendedMessageError= false
            this.errorSendMessage = ''

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        

                        if(response.data.data.error == 'Sin Resultados' || response.data.data.data.length<=0){

                          this.sendedMessageError= true
                          this.errorSendMessage = 'No results found'

                          this.consultCreated = false
                          this.smsRegs = []

                        }else if(response.data.data.data.length!=null && response.data.data.data.length>0){
                          
                            let regs = response.data.data.data
                            let newreg = null
                            let i=0

                            this.chartDataPeriodReport = response.data.data.report
                            this.chartDataPeriodReportINvOUT = response.data.data.report2

                            for(i=0; i<Object.keys(regs).length; i++){
                              //let theRecord = regs[i].record

                              let status = ''
                              //let recordG = null
                              let recordPrice = 0

                                if(regs[i].status=='A' && regs[i].type=='OUT'){
                                  status='Sended'
                                  /*recordG = JSON.parse(regs[i].record)
                                  if(recordG[2]['event']['fld-val-list']['previous_state'] == 'SENT'){
                                    recordPrice = recordG[2]['event']['fld-val-list']['mo_price']
                                  }*/
                                }else if(regs[i].status=='A' && regs[i].type=='IN'){
                                  status='Received'
                                  /*recordG = JSON.parse(regs[i].record)
                                  recordPrice = recordG[0]['event']['fld-val-list']['previous_state']
                                  //recordPrice = recordG[2].event.fld-val-list.mo_price

                                  console.log(recordG.event)*/
                                }else if(regs[i].status=='F'){
                                  status='Failed'
                                }else{
                                  status='Pending'
                                }

                              /*if(regs[i].type=='OUT'){
                                theRecord = regs[i].record
                              }else{
                                let recordJSON = JSON.parse(regs[i].record)
                                theRecord = recordJSON.event['fld-val-list']['message_id']
                                console.log(theRecord)
                              }*/
                              
                              

                                newreg = {'user_id': regs[i].organization_user_id, 'id': regs[i].id, 'type': regs[i].type, 'message_from':regs[i].message_from, 'message_to':regs[i].message_to, 'message':regs[i].message, 'status':status, 'record':regs[i].record_id, 'mo_price': recordPrice, 'created_at':regs[i].created_at}
                                this.$set(this.smsRegs, i, newreg)
                            }

                            this.okSendMessage= 'Consult finish'
                            
                            this.sendedMessageOk= true


                        }

                  
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.consultCreated = false
                        this.smsRegs = []
                        console.error(error)

                        this.sendedMessageError= true
                        this.errorSendMessage = error

                        
                        

                  //this.auth = false

                })
        },

        async obtainUsersOrg(){
            let url = this.urlAPI2+'?theT='+this.tokenGC
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        if(response.data.entities != null){
                            this.orgUsers = response.data.entities

                        }else{

                            this.orgUsers = null
                        }
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.orgUsers = null
                        console.error(error)

                  //this.auth = false

                })
        },

        async obtainOrgMessageTemplates(){
            let url = this.urlAPI3+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgMsgTemplates = response.data.data.data
                        }else{
                            this.orgMsgTemplates = null
                        }
                        
                    }).catch(error => {
                        this.orgMsgTemplates = null
                        console.error(error)

                })
        },

        async createNewMessageTemplates(){
            let url = this.urlAPI4
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            this.sendNewTemplate = true

            let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('message', this.newTemplate.message)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false

                      if(response.data.code == '01'){
                        console.log('New template created')
                        console.log(response)
                        this.obtainOrgMessageTemplates()
                      }else{
                        console.log('Template not created, try later.')
                        console.log(response.data.data.error)
                      }
                      

                    }).catch(error => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
        },

        
    },
    watch:{
      /*autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },*/
              
    },
        mounted(){

            let theAppLocal = null
            this.auth = false
        
            if(localStorage.getItem('esmtAppT160')==null){
                this.auth = false
            }else{
                theAppLocal = JSON.parse(localStorage.getItem('esmtAppT160'))

                /*if(theAppLocal.roles!=null && theAppLocal.u!=null){
                    this.$router.push({ name: 'App' })
                }else{*/
                    this.theApp = theAppLocal.client
                    this.clientId = theAppLocal.client.id
                    this.clientSecret = theAppLocal.client.secret
                    this.tokenGC = theAppLocal.t
                    this.userID = theAppLocal.u
                    this.userRoles = theAppLocal.roles
                    this.idOrgInDB = theAppLocal.client.id

                    this.validarRoles()

                    //this.obtainUsersOrgInDB()
                    //this.obtainUsersOrg()
                    //this.obtainOrgMessageTemplates()
                //}

                

              /*if(theAppLocal.t!=null){
                
              }*/
                

                //this.autoTokenGenerate = true
/*
                //this.auth = true

                //this.$router.push({ name: 'App' })*/
            }

            //console.log(theAppLocal)

        }
    
}
</script>
<style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
  height: 10px;
}
</style>