<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <SendSms @isAdmin="sendIsAdmin()"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import SendSms from '@/components/SendSMS.vue'

export default {
  name: 'Home',
  components: {
    SendSms
  },
  methods:{
    sendIsAdmin(){
      this.$emit('isAdmin', true)
    }
  }
}
</script>
