<template>
  
  <v-container>
    <v-alert
        dense
        type="success"
        style="position:absolute; clear:both; z-index:999; right:0px;"
        v-if="sendedMessageOk"
        @click="closeMessageSendedOk()"
      >
        <strong>Sending Message Complete</strong>
      </v-alert>
    <v-alert
        dense
        type="warning"
        color="red"
        style="position:absolute; clear:both; z-index:999; right:0px;"
        v-if="sendedMessageError"
        @click="closeMessageSendedError()"
      >
        <strong>Error: </strong>{{errorSendMessage}}
      </v-alert>

    <!-- Botones principales  -->
    <v-container style="max-width:800px;" v-if="theFunctions.length>0">
    <v-row v-if="typeSelected==''">
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="theFunctions.search('Mensaje Directo')>-1"
        >
        <v-card class="grey lighten-3" style="text-align:center; line-height:0px; cursor:pointer;" @click="openOption('Mensaje Directo')">
          <v-card-text>
          <v-img
            :src="require('@/assets/icon-direct-message-gray.svg')"
            transition="scale-transition"
            width="130"
            style="display:inline-block;"
          />
          <p class="font-weight-black" style="color:#272727">DM (Direct Message)</p>
          </v-card-text>
        </v-card>
      </v-col>
<!---->
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="GenesysCloudExternalContacts.length>0  && theFunctions.search('Mensaje Directo a Contacto Externo')>-1"
        >
          <v-card style="line-height:0px; text-align:center; color:#ff4f1f !important; cursor:pointer;" @click="openOption('Mensaje Directo a Contacto Externo')">
            <v-card-text>
              <v-img
                :src="require('@/assets/icon-direct-message-gray.svg')"
                transition="scale-transition"
                width="130"
                style="display:inline-block;"
              />
              <p class="font-weight-black" style="color:#272727">DM to External Contact</p>
            </v-card-text>
          </v-card>
      </v-col>

      
<!--v-if="GenesysCloudCampaingLists.length>0"-->
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="theFunctions.search('Masivo 1')>-1"
        >
        <v-card class="grey lighten-3" style="line-height:0px; text-align:center; cursor:pointer" @click="openOption('Masivo 1')">
          <v-card-text>
            <v-img
              :src="require('@/assets/icon-bulk-message-gray.svg')"
              transition="scale-transition"
              width="130"
                style="display:inline-block;"
            />
            <p class="font-weight-black m-0 p-0" style="color:#272727">Bulk SMS</p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="theFunctions.indexOf('Masivo WS')>-1"
        >
        <v-card class="grey lighten-3" style="line-height:0px; text-align:center; cursor:pointer" @click="openOption('Masivo WS')">
          <v-card-text>
            <v-img
              :src="require('@/assets/icon-bulk-WHATSAPP-gray.svg')"
              transition="scale-transition"
              width="130"
                style="display:inline-block;"
            />
            <p class="font-weight-black m-0 p-0" style="color:#272727">Bulk Whatsapp</p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- -->
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-if="theFunctions.search('Simple WS')>-1"
        >
        <v-card class="grey lighten-3" style="text-align:center; line-height:0px; cursor:pointer;" @click="openOption('Simple WS')">
          <v-card-text>
          <v-img
            :src="require('@/assets/icon-direct-message-gray.svg')"
            transition="scale-transition"
            width="130"
            style="display:inline-block;"
          />
          <p class="font-weight-black" style="color:#272727">Simple Whatsapp</p>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    </v-container>

    <v-row v-if="typeSelected!='' && typeSelected=='Mensaje Directo a Contacto Externo'">
      <v-card
        class="mx-auto my-12"
        style="width:95%;"
        outlined
      >
      <v-col
          cols="12"
          md="12"
          v-if="channels.length>0"
        >
        <v-select
          :items="channels"
          name="name"
          item-text="name"
          value="id"
          filled
          label="Sender Channel"
          @change="updateChannel"
        ></v-select>
      </v-col>

      <v-col
          cols="12"
          md="12"
        >
        <v-select
          :items="GenesysCloudExternalContacts"
          v-model="GenesysCloudExternalContactSelected"
          name="id"
          item-value="id"
          value="id"
          class="mb-0 pb-0"
          filled
          label="External Contact List"
          @change="updateExternalContactSelected"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.firstName }} {{ data.item.lastName }} <span class="ml-1" v-if="data.item.middleName!=null && data.item.middleName!=''">({{data.item.middleName}})</span>
          </template>
          <template slot="item" slot-scope="data">
             {{ data.item.firstName }} {{ data.item.lastName }}  <span class="ml-1" v-if="data.item.middleName!=null && data.item.middleName!=''">({{data.item.middleName}})</span>
          </template>
        </v-select>
        <v-chip
          color="blue"
          v-if="GenesysCloudExternalContactSelectedNumber!=''"
        >{{GenesysCloudExternalContactSelectedNumber}}</v-chip>

        <v-chip 
          v-if="GenesysCloudExternalContactSelectedNumber!=''"
          color="green"
        >Firstname: {{GenesysCloudExternalContactSelectedVars.firstName}}</v-chip>
        <v-chip 
          v-if="GenesysCloudExternalContactSelectedNumber!=''"
          color="green"
        >Lastname: {{GenesysCloudExternalContactSelectedVars.lastName}}</v-chip>
      </v-col>

      <v-col
          cols="12"
          md="12"
          v-if="orgMsgTemplates.length>0"
        >
        <v-select
          v-model="messageTemplateSelected"
          :items="orgMsgTemplates"
          name="message"
          item-text="message"
          value="id"
          filled
          label="Select Template"
          @change="updateMessage"
        ></v-select>
      </v-col>

      

      <v-col
          cols="12"
          md="12"
          v-if="messageTemplateSelected==''"
        >
        <v-text-field
          v-model="message.body"
          label="Message (max 150 chars)"
          :counter="150"
          required

        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="12"
        >
        <v-btn
          class="mr-4 success"
          @click="sendMessage()"
          :disabled="sendDisabled"
        >
          Send Message
        </v-btn>
        <v-btn @click="clear" class="mr-4">
          Clean
        </v-btn>
        <v-btn class="warning" @click="redo">
          Redo
        </v-btn>
      </v-col>
      </v-card>
    </v-row>
    <!-- -->
    
    <v-row v-if="typeSelected!='' && typeSelected=='Mensaje Directo'">
      <v-card
        class="mx-auto my-12"
        style="width:95%;"
        outlined
      >
      <v-col
          cols="12"
          md="12"
          v-if="channels.length>0"
        >
        <v-select
          :items="channels"
          name="name"
          item-text="name"
          value="id"
          filled
          label="Sending Channel"
          @change="updateChannel"
        ></v-select>
      </v-col>
      <v-row class="pl-4 pr-4">
        <v-col
            v-if="!isWhatsAppChannel"
            cols="2"
            md="2"
          >
          <v-text-field
            v-model="message.pre"
            label="Prefix"
            :counter="5"
            required
            disabled

          ></v-text-field>
        </v-col>
        <v-col
            :cols="!isWhatsAppChannel ? '10': '12'"
            :md="!isWhatsAppChannel ? '10': '12'"
          >
          <v-text-field
            v-model="message.to"
            :cols="!isWhatsAppChannel ? 'To (sin prefijo)': 'To (con prefijo. Ej: +584145.....)'"
            :counter="10"
            required

          ></v-text-field>
        </v-col>
      </v-row>
      <v-col
          cols="12"
          md="12"
        >
        <v-text-field
          v-model="message.body"
          label="Message (max 150 chars)"
          :counter="150"
          required

        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="12"
        >
        <v-btn
          class="mr-4 success"
          @click="sendMessage()"
          :disabled="sendDisabled"
        >
          Send Message
        </v-btn>
        <v-btn @click="clear" class="mr-4">
          Clean
        </v-btn>
        <v-btn class="warning" @click="redo">
          Redo
        </v-btn>
      </v-col>
      </v-card>
    </v-row>
    <!-- -->
    <v-row v-if="typeSelected!='' && typeSelected=='Masivo 1'">
      <v-card
        class="mx-auto my-12"
        style="width:95%;"
        outlined
      >
      <v-col
          cols="12"
          md="12"
        >

        <v-select
          v-model="contactListSelected"
          :items="contactLists"
          name="name"
          item-text="name"
          return-object
          filled
          label="Select ContactList"
          @change="getGenesysCloudContactListSelected"
          v-if="theFunctions.search('Listas Automaticas')>-1"
        ></v-select>
        
        <v-file-input
          v-model="file"
          truncate-length="15"
          label="Import .CSV Contact List"
          accept=".csv"
          clearable="true"
        ></v-file-input>

        <v-chip
          class="ma-1"
          style="background-color:#013c4c; color:#FFF; font-size:12px;"
          v-if="csvCant>0"
        >
          Contacts: {{csvCant}}
        </v-chip>
        <v-chip
          class="ma-1"
          style="font-size:12px;"
          color="warning"
          v-for="canales in channelsForFrom" :key="canales.id"
        > 
          Sending Channels: {{canales}}
        </v-chip>
        <div v-if="csv!='' && csv!=null && Object.keys(GenesysCloudCampaingListsVars).length>0">
          <span v-for="(index, variables) in csv.meta.fields" :key="variables.id">
          <v-chip
            class="ma-1 "
            x-small
            :style="[
                Object.keys(GenesysCloudCampaingListsVars).find(key => GenesysCloudCampaingListsVars[key] == index) ? {'backgroundColor': '#FFCC00'}:''
            ]"  
          >
            {{index}}
          </v-chip>
          </span>
        </div>
      </v-col>
      <v-col
          cols="12"
          md="12"
          v-if="channels.length>0"
        >
        <v-select
          :items="channels"
          name="name"
          item-text="name"
          value="id"
          filled
          label="Sender Channel"
          @change="updateChannel"
        ></v-select>
      </v-col>

      <v-col
          cols="12"
          md="12"
          v-if="orgCampaignLists!=null && orgCampaignLists.length>0"
        >
        <v-select
          v-model="messageCampaignSelected"
          :items="orgCampaignLists"
          name="name"
          item-text="name"
          value="id"
          item-value="name"
          filled
          label="Select Campaign"
          @change="getAllTemplates"
        ></v-select>
      </v-col>

      <v-col
          cols="12"
          md="12"
          v-if="orgMsgTemplatesFiltered!=null && orgMsgTemplatesFiltered.length>0"
        >
        <v-select
          v-model="messageTemplateSelected"
          :items="orgMsgTemplatesFiltered"
          name="message"
          item-text="message"
          value="id"
          filled
          label="Select Template"
          @change="updateMessage2"
        ></v-select>
        <span v-if="messageTemplateSelected!='' && csv!=null">
          <v-chip 
            class="ma-1"
            v-for="(index, variables) in GenesysCloudCampaingListsVars"
            :key="variables.id"
            color="green"
          >{{index}}</v-chip>
        </span>
      </v-col>

      <v-col
          cols="12"
          md="12"
        >
        <v-btn
          class="mr-4"
          style="background-color:#013c4c; color:#FFF;"
          @click="sendMessageBulk()"
          :disabled="sendDisabled || !csvImport || messageTemplateSelected==''"
        >
          Send Bulk Message
        </v-btn>
        <v-btn @click="clear" class="mr-4">
          Clean
        </v-btn>
        <v-btn class="warning" @click="redo">
          Redo
        </v-btn>
      </v-col>
      </v-card>
    </v-row>
    <!-- -->
    <v-row v-if="typeSelected!='' && typeSelected=='Masivo WS'">
      <v-card
        class="mx-auto my-12"
        style="width:95%;"
        outlined
      >

      <v-col
          cols="12"
          md="12"
          v-if="channels.length>0"
        >
        <v-select
          :items="filterWSChannels()"
          name="name"
          item-text="name"
          item-value="name"
          filled
          label="Canal de envío"
          @change="updateChannelWS"
        ></v-select>
      </v-col>

      <v-col
          cols="12"
          md="12"
          v-if="isWhatsAppChannel"
        >
        <v-select 
          :items="selectedChannelWSTemplates"
          v-model="selectedChannelWSTemplatesSelected"
          name="name"
          item-text="name"
          filled
          label="Elige una plantilla"
          @change="updateCampaing2"
        ></v-select>
      </v-col>

      <v-col
          cols="12"
          md="12"
        >
        
        <v-file-input
          v-model="file"
          truncate-length="15"
          label="Import .CSV Contact List"
          accept=".csv"
          clearable="true"
        ></v-file-input>

        <v-chip
          class="ma-1"
          style="background-color:#013c4c; color:#FFF; font-size:12px;"
          v-if="csvCant>0"
        >
          Contacts: {{csvCant}}
        </v-chip>
        <v-chip
          class="ma-1"
          style="font-size:12px;"
          color="warning"
          v-for="canales in channelsForFrom" :key="canales.id"
        >
          Sending Channels: {{canales}}
        </v-chip>
        <div v-if="csv!='' && csv!=null && Object.keys(GenesysCloudCampaingListsVars).length>0">
          <span v-for="(index, variables) in csv.meta.fields" :key="variables.id">
          <v-chip
            class="ma-1 "
            x-small
            :style="[
                Object.keys(GenesysCloudCampaingListsVars).find(key => GenesysCloudCampaingListsVars[key] == index) ? {'backgroundColor': '#FFCC00'}:''
            ]"  
          >
            {{index}}
          </v-chip>
          </span>
        </div>
      </v-col>

      <!--<v-col
          cols="12"
          md="12"
          v-if="templates.length>0"
        >
        <v-select
          v-model="messageTemplateSelected"
          :items="orgMsgTemplates"
          name="message"
          item-text="message"
          value="id"
          filled
          label="Select Template"
          @change="updateMessage2"
        ></v-select>
        <span v-if="messageTemplateSelected!='' && csv!=null">
          <v-chip 
            class="ma-1"
            v-for="(index, variables) in GenesysCloudCampaingListsVars"
            :key="variables.id"
            color="green"
          >{{index}}</v-chip>
        </span>
      </v-col>

      <v-col
          cols="12"
          md="12"
        >
        <v-text-field
          v-model="message.body"
          label="Mensaje (max 150 chars)"
          :counter="150"
          required

        ></v-text-field>
      </v-col>-->

      <v-col
          cols="12"
          md="12"
        >
        <!--
this.selectedChannelWS = this.channels[i]
              this.selectedChannelWSTemplates = this.selectedChannelWS['data0']
          -->
        <v-btn
          class="mr-4 success"
          @click="sendMessageBulk2()"
          :disabled="sendDisabled || selectedChannelWS=='' || csvImport==false"
        >
          Send Whatsapp Bulk Message
        </v-btn>
        <v-btn class="warning" @click="redo">
          Redo
        </v-btn>
      </v-col>
      
      </v-card>
    </v-row>
    <!-- -->
    <v-row v-if="smsRegs.length>0">
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      <v-card-title class="m-4">Registers ({{smsRegs.length}})</v-card-title>
      <v-data-table style="width:100%; font-size:11px;"  :headers="headers" :search="search" :items="smsRegs" item-key="id">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr
              :class="[item.type=='IN' ? 'light-green lighten-3':'yellow accent-1']"
            >
              <td style="font-size:11px;">{{ item.type }}</td>
              <td style="font-size:11px;">{{ item.message_from }}</td>
              <td style="font-size:11px;">{{ item.message_to }}</td>
              <!--<td style="font-size:11px;" width="30%">{{ item.message.substring(0,50)+".." }}</td>-->
              <td style="font-size:11px;" :class="[item.status=='Failed' ? 'red lighten-1':'']">
              
                  {{ item.status }}
              </td>
              <td style="font-size:11px;" :class="[item.status=='Failed' ? 'red lighten-1':'']">
                  {{ item.statusB }}
              </td>
              <td style="font-size:11px;">
                  {{ item.campaign.substring(0,50)+".." }}
              </td>
              <td style="font-size:11px;">{{ item.record }}</td>
              <td style="font-size:11px;">{{ item.created_at }}</td>
            </tr>
        </template>
        
      </v-data-table>
      </v-card>
    </v-row>
    <!-- -->
  </v-container>
</template>
<script>
import axios from 'axios'
import Papa from 'papaparse';

export default {
    name:'SendSMS',
    components:{
      //VueCsvImport

    },
    data() {
        return {
            errorSendMessage:'',
            okSendMessage:'',
            sendedMessageOk:false,
            sendedMessageError:false,
            headers:[
              {'text': 'Type', 'value':'type'},
              {'text': 'Channel/From', 'value':'message_from'},
              {'text': 'To', 'value':'message_to'},
              //{'text': 'Body', 'value':'message'},
              {'text': 'Status', 'value':'status'},
              {'text': 'Status2', 'value':'status2'},
              {'text': 'Campaign', 'value':'campaign'},
              {
                'text': 'ID', 
                'value':'record'
              },
              {'text': 'Created at', 'value':'created_at'},
            ],
            search:'',
            file: '',
            content:[],
            parsed: false,
            csv:{},
            csvCant:0,
            csvImport:false,
            channelsForFrom:[],
            idOrgInDB: '',
            clientId: '',
            redirectUri: '',
            clientSecret:'',
            userID: '',
            userRoles:null,
            tokenGC: '',
            token: '',
            message:{
                from: "",
                pre: "",
                to: "",
                body: "",
                finalmessage: "",
                provider: ""
            },
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            valid:false,
            typeSelected:'',
            type:['Mensaje Directo', 'Masivo 1', 'Masivo WS'],
            //token: 'Bearer d34120be-bd82-3411-9986-5f12ea10afde', 
            urlAPI0: this.$urlAPI+'syniverse_simple_message.php',
            urlAPI: '_simple_message.php',
            urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
            urlAPI3: this.$urlAPI+'smsApp_insert_outbound_message.php',
            urlAPI33: this.$urlAPI+'smsApp_failed_outbound_message.php',
            urlAPI4: this.$urlAPI+'smsApp_getAll100messages.php',
            urlAPI5: this.$urlAPI+'smsApp_getTheLastMessage.php',
            urlAPI6: this.$urlAPI+'smsApp_configs_getMessagesTemplates.php',
            urlAPI7: this.$urlAPI+'genesys_simple_message.php',
            urlAPI8: this.$urlAPI+'esmt_ws_send_message_template.php',
            urlAPI9: this.$urlAPI+'campaign_get_all.php',
            selectedChannel: '',
            selectedChannelWS:null,
            selectedChannelWSTemplates:null,
            selectedChannelWSTemplatesSelected:'',
            defaultMessage: 'Saludos desde ESMT, prueba 1 de envío de sms masivo desde App que se integrará a Genesys Cloud',
            GenesysCloudExternalContactsC:null,
            GenesysCloudExternalContacts:[],
            GenesysCloudExternalContactSelected:'',
            GenesysCloudExternalContactSelectedNumber:'',
            GenesysCloudExternalContactSelectedVars:{
              firstName:''
            },
            GenesysCloudCampaingLists:[],
            GenesysCloudCampaingListSelected:'',
            GenesysCloudCampaingListsVars:{

            },
            contactLists:[],
            contactListSelected:'',
            isWhatsAppChannel:false,
            //QpKGqKe9lywI44igz1EiI1
            channels:[
              {'name':'EstadosUnidos1', 'id': 'QpKGqKe9lywI44igz1EiI1', 'pre': '+1'},
              {'name':'España34', 'id': 'zm8lO9Y9QKGKTeS-BoHCKA', 'pre': '+34'},
              {'name':'Mexico52', 'id': 'viJqIDhDxIdxtB9WWeCDt1', 'pre': '+52'},
              {'name':'Argentina54', 'id': 'mcrCX8qY4UXFDF3gf8jvF6', 'pre': '+54'},
              {'name':'Colombia57', 'id': '0tQeNecXU9finQcXSrH456', 'pre': '+57'}
            ],/**/
            smsRegs:[],
            smsList:[{
              'name': 'Lista1', 'list':[
                {'from': 'Mexico52',	'to':'+525564958184'},
                {'from': 'Argentina54',	'to':'+541133537599'},
                {'from': 'EstadosUnidos1',	'to':'+17867866216'}
              ]
            },{
              'name': 'Lista2', 'list':[
                {'from': 'Colombia57',	'to':'+573133692179'},
                {'from': 'Mexico52',	'to':'+525518540436'},
                {'from': 'Colombia57',	'to':'+573153164878'},

              ]
            }],
            smsList2:[{
              'name': 'Listado Colombia', 'from': 'Colombia57', 'list':[
                {'to':'+573133692179'},
                {'to':'+573153164878'},
              ]
            },{
              'name': 'Listado Mexico', 'from': 'Mexico52', 'list':[
                {'to':'+525564958184'},
                {'to':'+525518540436'},
              ]
            },{
              'name': 'Listado Argentina', 'from': 'Argentina54', 'list':[
                {'to':'+541133537599'},
              ]
            },{
              'name': 'Listado Estados Unidos', 'from': 'EstadosUnidos1', 'list':[
                {'to':'+17867866216'},
              ]
            }],
            orgMsgTemplates:null,
            orgMsgTemplatesFiltered:null,
            orgCampaignLists:null,
            messageTemplateSelected:'',
            messageCampaignSelected:'',
            messageCampaignMedia:'',
            theApp:{
              name: '',
              description: '',
              status:''
            },
            theFunctions:'',
            selectedCampaing: '',
            selectedCampaingList: null,
            selectedCampaingListTo: [],
            sendDisabled:false,
            isGuest:false,
            orgMsgTemplatesFiltering:''
           
        }
    },
    watch:{
      'theApp': function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  this.getGenesysCloudExternalContacts()
                }

      },
      file: function(newValue){
        if(newValue!=null){
          this.parseFile()
        }
      }
              
    },
    methods:{
      getAllTemplates(e=null){
        if(e!=null){
          this.orgMsgTemplatesFiltering = e
        }
        console.log('Filtrando templates')
        if(this.orgMsgTemplatesFiltering!=''){
          console.log('Filtrado 1 exitoso')
          //let all = this.allConversations.filter(item => (item.participants.find(purpose => purpose.purpose === 'customer')?.attributes.pstatus == 'not-paid'))
           this.orgMsgTemplatesFiltered = this.orgMsgTemplates.filter(item => item.organizations_campaigns_id === this.orgMsgTemplatesFiltering)
        }else{
          console.log('Filtrado 2 exitoso')
           this.orgMsgTemplatesFiltered = this.orgMsgTemplates.filter(item => item.organizations_campaigns_id === null || item.organizations_campaigns_id === '')
        }

        
      },
      updateMessage(){
        this.message.body = this.messageTemplateSelected
      },

      filterWSChannels(){
        let i=0
        let e=0
        let ob = []
        for(i=0; i<this.channels.length; i++){
          
          if(this.channels[i].pre == 'WS'){
              
              ob.push(this.channels[i])
              console.log(ob)

              let revData = ob[e]['data0']

              if(revData!=null){
                if(revData.indexOf(",")>=0){
                  let objArr = ob[e]['data0'].split(',')
                  ob[e]['data0'] = objArr
                }else{
                  ob[e]['data0'] = revData
                }
                
               // ob[e]['data0'] = objArr
              }
              e++
          }
        }

        return ob
      },

      updateChannelWS(v){
          console.log('Channel WS')
          console.log(v)
          this.selectedChannelWSTemplates = null
          this.selectedChannelWS = null
          let i=0;
          for(i=0; i<this.channels.length; i++){
            if(this.channels[i].name == v){
              
              /*ob.push(this.channels[i])
              console.log(ob)
              
              if(ob[e]['data0']!=null){
                let objArr = ob[e]['data0'].split(',')
                ob[e]['data0'] = objArr
              }
              e++*/
              
              
              this.isWhatsAppChannel = true
              this.selectedChannelWS = this.channels[i]
              this.selectedChannelWSTemplates = this.selectedChannelWS['data0']
            }
          }
          /*0*/
        },

      updateMessage2(){

        this.GenesysCloudCampaingListsVars={}
        this.message.body = this.messageTemplateSelected

        const regex = /{([^}]+)}/g;
        const resultados = [];
        let resultado;
        while ((resultado = regex.exec(this.message.body))) {
          resultados.push(resultado[1]);
        }

        this.GenesysCloudCampaingListsVars = resultados
        //return resultados;
/*
        //let TheMessageTemplate = this.message.body
            let posiciones = null
            //let variables = {}
            //let variables2 = {}
            this.GenesysCloudCampaingListsVars={}
            
            //this.message.finalmessage = TheMessageTemplate = this.message.body

            if(this.messageTemplateSelected!=''){
              //TheMessageTemplate = this.message.body.replace('[firstName]', this.GenesysCloudExternalContactSelectedVars.firstName);
              posiciones = this.getIndices(this.message.body, '{')
              let i=0

              for(i=0; i<posiciones.length; i++){
                this.GenesysCloudCampaingListsVars[i] = this.message.body.substring(posiciones[i]-1, this.message.body.indexOf(' ', posiciones[i]-1))
                //variables2[i] = this.message.body.substring(posiciones[i]-1, this.message.body.indexOf(' ', posiciones[i]-1))
                // = variables[i].replace(',', '')
                this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace(',', '')
                this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace('.', '')
                //this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace('{', ' {')
                this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace('{', '')
                //this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace('}', '} ')
                this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace('}', '')
                this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace('!', '')
                this.GenesysCloudCampaingListsVars[i] = this.GenesysCloudCampaingListsVars[i].replace(' ', '')
                //variables2[i] = variables2[i].replace('{', '')
                //variables2[i] = variables2[i].replace('}', '')
                
                //TheMessageTemplate = TheMessageTemplate.replace(variables[i], this.GenesysCloudExternalContactSelectedVars[variables2[i]])
              }
            }*/
            console.log('Variables detectadas')
            console.log(this.GenesysCloudCampaingListsVars)
      },

      async getFunctions(){
        await fetch('https://t160api.esmtcx.solutions/getFunctions.php?id_organization='+this.idOrgInDB)
              .then(response => response.json())
              .then(result => {
                //let i = 0
                ///let key = 0
                this.theFunctions = result.data

                /*for(i=0; i<this.GenesysCloudExternalContactsC.length; i++){
                  if(this.GenesysCloudExternalContactsC[i].workPhone != null){
   
                      this.$set(this.GenesysCloudExternalContacts, key, this.GenesysCloudExternalContactsC[i])
       
                    key++
                  }
                }*/
                //this.GenesysCloudExternalContacts = result.entities
                console.log(result)
              })
              .catch(error => console.log('error', error));
      },

      closeMessageSendedOk(){
        this.sendedMessageOk = false
        this.okSendMessage =''
      },
      closeMessageSendedError(){
        this.sendedMessageError = false
        this.errorSendMessage =''
      },
      handleFileUpload(event){
          this.file = event.target.files[0];
          this.parseFile();
      },
      searchChannelsInCSV(){
        let i=0
        let canales = this.channels
        let list = this.csv.data
        let count = 0

        console.log(list.length)
        console.log(canales.length)
        for(i=0; i<list.length; i++){
          let a=0
          for(a=0; a<canales.length; a++){
            if(canales[a].name == list[i].SMSChannel && this.channelsForFrom.indexOf(list[i].SMSChannel)<0){
              this.channelsForFrom[count] = list[i].SMSChannel
              count++
            }
          }
        }
      },

      parseFile(){
          Papa.parse( this.file, {
              header: true,
              skipEmptyLines: true,
              complete: function( results ){
                  this.csvImport = true
                  this.csv = results
                  this.csvCant  = this.csv.data.length
                  this.searchChannelsInCSV()
                  this.parsed = true
              }.bind(this)
          } );
      },

        authToken(){
          window.open('https://login.mypurecloud.com/oauth/authorize?client_id='+this.clientId+'&response_type=token&redirect_uri='+this.redirectUri+'', '_blank');
        },

        authApp(){
          //client = platformClient.ApiClient.instance
          //https://api.mypurecloud.com/api/v2/oauth/clients/5bd9a07a-9c23-4d4f-8135-b4ca08e91358
          axios.get('https://api.mypurecloud.com/api/v2/oauth/clients/'+this.clientId, {
              headers:{
                'Authorization': 'Bearer '+this.tokenGC, 
                /*'Access-Control-Allow-Origin': '*',
                'Accept': '*',
                'Content-Type': 'application/json'*/
              }
            }).then(response => {
                  console.log(response)

                  localStorage.setItem('app', JSON.stringify(response.data))

                  this.theApp.name = response.data.name
                  this.theApp.description = response.data.description
                  this.theApp.state = response.data.state

                  localStorage.setItem('tgc', this.tokenGC)

                  this.auth = true

                }).catch(error => {
                    console.error(error)

                  this.auth = false

                })
        },

        async getGenesysCloudExternalContacts(){
          //client = platformClient.ApiClient.instance
          //https://api.mypurecloud.com/api/v2/oauth/clients/5bd9a07a-9c23-4d4f-8135-b4ca08e91358
          /*var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+this.tokenGC+"");
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };

            fetch("https://api.mypurecloud.com/api/v2/externalcontacts/contacts", requestOptions)
              .then(response => response.json())
              .then(result => {
                let i = 0
                let key = 0
                this.GenesysCloudExternalContactsC = result.entities

                for(i=0; i<this.GenesysCloudExternalContactsC.length; i++){
                  if(this.GenesysCloudExternalContactsC[i].workPhone != null){
   
                      this.$set(this.GenesysCloudExternalContacts, key, this.GenesysCloudExternalContactsC[i])
       
                    key++
                  }
                }
                //this.GenesysCloudExternalContacts = result.entities
                console.log(result)
              })
              .catch(error => console.log('error', error));*/


              await axios.get(this.$urlAPI+'genesys_get_external_contacts.php?tgc='+this.tokenGC, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)

                        let i = 0
                        let key = 0
                        this.GenesysCloudExternalContactsC = response.data.entities

                        for(i=0; i<this.GenesysCloudExternalContactsC.length; i++){
                          if(this.GenesysCloudExternalContactsC[i].workPhone != null){
          
                              this.$set(this.GenesysCloudExternalContacts, key, this.GenesysCloudExternalContactsC[i])
              
                            key++
                          }
                        }
                        //this.GenesysCloudExternalContacts = result.entities
                        //console.log(response)

                    }).catch(error => {
                        console.log('error', error)

                  //this.auth = false

                })
        },

        async GenesysCloudMe(){
          ///api/v2/users/me?expand=authorization

         
        },

        validarRoles(){
          let i=0

          for(i=0; i<this.userRoles.length; i++){
            if(this.userRoles[i].name == 'admin'){
              this.$emit('isAdmin', true)
              return false
            }
          }

          if(this.userRoles[0].name=='guest'){
            this.isGuest = true
          }

          //this.$router.push({ name: 'Error' })
        },

        async getGenesysCloudCampainLists(){
          

           /*var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+this.tokenGC+"");
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };*/

            await fetch('https://t160api.esmtcx.solutions/genesys_get_all_contact_lists.php?tgc='+this.tokenGC)
              .then(response => response.json())
              .then(result => {
                //let i = 0
                ///let key = 0
                this.GenesysCloudCampaingLists = result.entities

                /*for(i=0; i<this.GenesysCloudExternalContactsC.length; i++){
                  if(this.GenesysCloudExternalContactsC[i].workPhone != null){
   
                      this.$set(this.GenesysCloudExternalContacts, key, this.GenesysCloudExternalContactsC[i])
       
                    key++
                  }
                }*/
                //this.GenesysCloudExternalContacts = result.entities
                console.log(result)
              })
              .catch(error => console.log('error', error));
        },

        updateCampaingListSelected(v){
          console.log(v)
        },

        updateExternalContactSelected(v){
          console.log(v)
          this.GenesysCloudExternalContactSelectedNumber = ''
          let i=0
          for(i=0; i<this.GenesysCloudExternalContacts.length; i++){
            // Valido en el arreglo el ID seleccionado para trabajar sobre ese nodo
            if(this.GenesysCloudExternalContacts[i].id == this.GenesysCloudExternalContactSelected){
              //Verifico que el objeto workphone existe
              if(this.GenesysCloudExternalContacts[i].workPhone!=null){
                //userInput
                //display
                //e164
                
                this.GenesysCloudExternalContactSelectedVars.firstName = this.GenesysCloudExternalContacts[i].firstName
                this.GenesysCloudExternalContactSelectedVars.lastName = this.GenesysCloudExternalContacts[i].lastName

                if(this.GenesysCloudExternalContacts[i].workPhone.e164!=null && this.GenesysCloudExternalContacts[i].workPhone.e164!=''){
                  this.GenesysCloudExternalContactSelectedNumber = this.GenesysCloudExternalContacts[i].workPhone.e164
                  this.message.to = this.GenesysCloudExternalContacts[i].workPhone.e164
                  
                }else if(this.GenesysCloudExternalContacts[i].workPhone.userInput!=null && this.GenesysCloudExternalContacts[i].workPhone.userInput!=''){
                  this.GenesysCloudExternalContactSelectedNumber = this.GenesysCloudExternalContacts[i].workPhone.userInput
                  this.message.to = this.GenesysCloudExternalContacts[i].workPhone.userInput

                }else if(this.GenesysCloudExternalContacts[i].workPhone.display!=null && this.GenesysCloudExternalContacts[i].workPhone.display!=''){
                  this.GenesysCloudExternalContactSelectedNumber = this.GenesysCloudExternalContacts[i].workPhone.display
                  this.message.to = this.GenesysCloudExternalContacts[i].workPhone.display
                }
                
              }
            }

          }
        },

        redo(){
          this.typeSelected = ''
          /*this.csv = null
          this.csvImport = false
          this.file = null*/
          this.clear()
        },
        openOption(v){
          //this.$emit('redoU')
          this.typeSelected = v
        },
        clearBulkMessage(){

        },

        clearSendExternalContact(){
            
        },
        clear(){ 
          this.GenesysCloudExternalContactSelected = '',
            this.GenesysCloudExternalContactSelectedNumber = '',
            this.messageTemplateSelected = ''
            this.selectedChannel = ''
            this.message.from = '',
            this.message.to = '',
            this.message.pre = '',
            this.message.body = ''
            this.message.finalmessage = ''
            this.messageTemplateSelected = ''
            this.csvImport = false,
            this.csv = []
            this.file = null
            this.csvCant = 0
            this.channelsForFrom = []
            this.messageCampaignSelected=''
            this.messageCampaignMedia=''
            //this.message.body = this.defaultMessage  
        },
        //Masivo 1
        updateCampaing(v){
          console.log(v)
          let i=0

          this.selectedCampaing = ''
          this.selectedCampaingList = null

          for(i=0; i<this.smsList.length; i++){
            if(this.smsList[i].name==v){
              this.selectedCampaing = v
              this.selectedCampaingList = this.smsList[i].list
              console.log(this.selectedCampaingList)
              break
            }
          }
        },
        //Masivo WS
        updateCampaing2(v){
          console.log(v)
          let i=0

          this.selectedCampaing = ''
          this.selectedCampaingList = null

          for(i=0; i<this.smsList2.length; i++){
            if(this.smsList2[i].name==v){
              this.selectedCampaing = v
              this.selectedCampaingList = this.smsList2[i].list
              let a = 0
              for(a=0; a<this.selectedCampaingList.length; a++){
                  this.selectedCampaingListTo[a] = this.selectedCampaingList[a].to
              }
              console.log(this.selectedCampaingList)
              console.log(this.selectedCampaingListTo)
              break
            }
          }
        },

        updateChannel(v){
          console.log(v)
          let i=0
          this.selectedChannel = ''
          for(i=0; i<this.channels.length; i++){
            
            if(this.channels[i].name==v){
              if(this.channels[i].pre == 'WS'){
                this.isWhatsAppChannel = true
              }

              this.message.from = this.channels[i].id
              this.message.pre = this.channels[i].pre
              this.selectedChannel = v
              this.message.provider = this.channels[i].provider
              break
            }
          }
        },

        sendMessageToDB(idMessage, from=null, to=null, message=null, record=null, iscampaign=null, t=null, i=null, r=null, ig=null){

            let url = this.urlAPI3

            let data = new FormData();

            //this.sendDisabled = true

            let toNumber = this.message.to
            let newToNumber = ''

            if(toNumber.indexOf(this.message.pre)>-1){
              newToNumber = toNumber.replace(this.message.pre, '');
            }else{
              newToNumber = this.message.pre+this.message.to
            }

            let fromM  = ''
            let toM  = ''
            let messageM = ''
            let recordM = ''
            let isCampaignM = iscampaign
            
            if(from!=null){
              fromM=from
            }else{
              fromM=this.message.from
            }

            if(to!=null){
              toM=to
            }else{
              toM=newToNumber
            }

            if(message!=null){
              messageM = message
            }else{
              messageM = this.message.finalmessage
            }

            if(record!=null){
              recordM = record
            }


            /*
            $type = 'OUT'; $from = $_POST['from_message']; $to = $_POST['to_message']; $message = $_POST['message']; $id_message = $_POST['id_message']; $_POST['id_organization']
            */

            data.append('id_organization', this.idOrgInDB)
            data.append('from_message', fromM)
            data.append('to_message', toM)
            data.append('message', messageM)
            data.append('record', recordM)
            data.append('id_message', idMessage)
            data.append('organization_user_id', this.userID)
            data.append('isCampaign', isCampaignM)
            data.append('theT', t)
            data.append('theI', i)
            data.append('ref', r)
            data.append('insertgenesys',ig)
            

            axios.post(url, data, {
              headers:{

              }
            })
                .then(response => {
                  console.log('Create new message into DB')
                  console.log(response)

                }).catch(error => {
                  console.log('Create new message into DB')
                  console.log(error)
                })
        },

        sendMessageFailedToDB(idMessage, from=null, to=null, message=null, record=null, iscampaign=null, t=null, i=null, r=null, ig=null){
          let url = this.urlAPI33

            let data = new FormData();

            //this.sendDisabled = true

            let toNumber = this.message.to
            let newToNumber = ''

            if(toNumber.indexOf(this.message.pre)>-1){
              newToNumber = toNumber.replace(this.message.pre, '');
            }else{
              newToNumber = this.message.pre+this.message.to
            }

            let fromM  = ''
            let toM  = ''
            let messageM = ''
            let recordM = ''
            let isCampaignM = iscampaign
            
            if(from!=null){
              fromM=from
            }else{
              fromM=this.message.from
            }

            if(to!=null){
              toM=to
            }else{
              toM=newToNumber
            }

            if(message!=null){
              messageM = message
            }else{
              messageM = this.message.finalmessage
            }

            if(record!=null){
              recordM = record
            }


            /*
            $type = 'OUT'; $from = $_POST['from_message']; $to = $_POST['to_message']; $message = $_POST['message']; $id_message = $_POST['id_message']; $_POST['id_organization']
            */

            data.append('id_organization', this.idOrgInDB)
            data.append('from_message', fromM)
            data.append('to_message', toM)
            data.append('message', messageM)
            data.append('record', recordM)
            data.append('id_message', idMessage)
            data.append('organization_user_id', this.userID)
            data.append('isCampaign', isCampaignM)
            data.append('theT', t)
            data.append('theI', i)
            data.append('ref', r)
            data.append('insertgenesys',ig)
            

            axios.post(url, data, {
              headers:{

              }
            })
                .then(response => {
                  console.log('Create new message into DB')
                  console.log(response)

                }).catch(error => {
                  console.log('Create new message into DB')
                  console.log(error)
                })
        },

        getAllMessages100(){

            let url = this.urlAPI4+'?id_organization='+this.idOrgInDB+'&id_user='+this.userID

            console.log('Url all messages request '+url)

            axios.get(url,{
              headers:{

              }
            })
                .then(response => {
                  //this.smsRegs = response.data.data.data

                  let regs = response.data.data.data

                  let newreg = null
                  let i=0

                  for(i=0; i<Object.keys(regs).length; i++){
                    //let theRecord = regs[i].record

                    let status = ''

                      if(regs[i].status=='A' && regs[i].type=='OUT'){
                        status='Sended'
                      }else if(regs[i].status=='A' && regs[i].type=='IN'){
                        status='Received'
                      }else if(regs[i].status=='F'){
                        status='Failed'
                      }else{
                        status='Pending'
                      }

                    let statusB = ''

                      if(regs[i].statusB!='Failed' && regs[i].statusB!='FAILED' && regs[i].statusB!='ERROR'){
                        statusB = 'Delivered'
                      }else{
                        statusB = 'Failed'
                      }

                    /*if(regs[i].type=='OUT'){
                      theRecord = regs[i].record
                    }else{
                      let recordJSON = JSON.parse(regs[i].record)
                      theRecord = recordJSON.event['fld-val-list']['message_id']
                      console.log(theRecord)
                    }*/
                    
                      newreg = {'id': regs[i].id, 'type': regs[i].type, 'message_from':regs[i].message_from, 'message_to':regs[i].message_to, 'message':regs[i].message, 'status':status, 'statusB': statusB, 'record':regs[i].record_id, 'campaign':regs[i].isCampaign, 'created_at':regs[i].created_at}
                      this.$set(this.smsRegs, i, newreg)
                  }
                    //this.sendMessageToDB(response.data.id)

                  setInterval(this.getLastMessage,15000);

                  console.log('Load all messages')
                  //console.log(response)

                }).catch(error => {
                  console.log('Load all messages failed')
                  console.log(error)
                })
        },

        async getLastMessage(){

            let url = this.urlAPI5+'?id_organization='+this.idOrgInDB+'&lID='+this.smsRegs[0].id+'&id_user='+this.userID

            //console.log('Url all messages request '+url)

            await axios.get(url,{
              headers:{

              }
            })
                .then(response => {

                  this.closeMessageSendedOk()
                  this.closeMessageSendedError()

                  //console.log('Consult last message')
                  //console.log(response)

                  let lastIDconsult= response.data.lID

                  let lastIDinSMSRegs = this.smsRegs[0].id

                  if(lastIDconsult == lastIDinSMSRegs){
                    //console.log('No hay nuevos registros')
                  }else{
                    //console.log('Hay nuevos mensajes creados. Ultimo ID: '+lastIDconsult)

                    
                    let i=0

                    let regs = response.data.data.data

                    let newArray = []
                    let oldArray = this.smsRegs

                    let nm = 0

                    for(i=0; i<Object.keys(regs).length; i++){
                      //let theRecord = null
                      let newreg = null

                      if(regs[i].status=='A' && regs[i].type=='OUT'){
                        status='Sended'
                      }else if(regs[i].status=='A' && regs[i].type=='IN'){
                        status='Received'
                      }else{
                        status='Not Sended'
                      }

                      let status = ''
                      if(regs[i].status=='A' && regs[i].type=='OUT'){
                        status='Sended'
                      }else if(regs[i].status=='A' && regs[i].type=='IN'){
                        status='Received'
                      }else if(regs[i].status=='F'){
                        status='Failed'
                      }else{
                        status='Pending'
                      }

                      let message = regs[i].message
                      if(regs[i].message_from=='Whatsapp'){
                        message = regs[i].message
                      }
                      
                      newreg = {'id': regs[i].id, 'type': regs[i].type, 'message_from':regs[i].message_from, 'message_to':regs[i].message_to, 'message':message, 'status':status, 'record':regs[i].record_id, 'campaign':regs[i].isCampaign, 'created_at':regs[i].created_at}
                      if(nm==0){
                        newArray[nm] = newreg
                      }else{
                        this.$set(newArray, nm, newreg)
                      }

                      nm++                        
                        //this.$set(this.smsRegs, 0, newreg)
                    }

                    let a = 0
                    for(a=0; a<Object.keys(oldArray).length; a++){
                        //newreg = {'id': regs[i].id, 'type': regs[i].type, 'message_from':regs[i].message_from, 'message_to':regs[i].message_to, 'message':regs[i].message, 'status':regs[i].status, 'record':theRecord, 'created_at':regs[i].created_at}
                        this.$set(newArray, nm, oldArray[a])
                        nm++
                        //this.$set(this.smsRegs, 0, newreg)
                    }

                    //this.smsRegs = {}
                    this.smsRegs = null
                    this.smsRegs = newArray

                    this.errorSendMessage = ''
                    this.sendedMessageOk = false
                    this.okSendMessage =''
                    this.sendedMessageError = false


                  }

                }).catch(error => {
                  this.closeMessageSendedOk()
                  this.closeMessageSendedError()
                  console.log('Load all messages failed')
                  console.log(error)
                })
        },

        getIndices(s, t){
          return [...s].flatMap((char, i) => (char === t ? i + 1 : []));
        },

        extraerPalabrasEntreLlaves(str) {
          const regex = /{([^}]+)}/g;
          const matches = [];
          let match;
          while ((match = regex.exec(str))) {
            matches.push(match[1]);
          }
          return matches;
        },

        async sendMessage(){

            let TheMessageTemplate = ''
            let posiciones = null
            let variables = {}
            let variables2 = {}
            
            this.message.finalmessage = TheMessageTemplate = this.message.body

            if(this.messageTemplateSelected!=''){
              //TheMessageTemplate = this.message.body.replace('[firstName]', this.GenesysCloudExternalContactSelectedVars.firstName);
              posiciones = this.getIndices(this.message.body, '{')
              let i=0

              for(i=0; i<posiciones.length; i++){
                variables[i] = this.message.body.substring(posiciones[i]-1, this.message.body.indexOf(' ', posiciones[i]-1))
                variables2[i] = this.message.body.substring(posiciones[i]-1, this.message.body.indexOf(' ', posiciones[i]-1))
                variables[i] = variables[i].replace(',', '')
                variables2[i] = variables2[i].replace(',', '')
                variables2[i] = variables2[i].replace('{', '')
                variables2[i] = variables2[i].replace('}', '')
                
                TheMessageTemplate = TheMessageTemplate.replace(variables[i], this.GenesysCloudExternalContactSelectedVars[variables2[i]])
              }
            }
            
            this.message.finalmessage = TheMessageTemplate

            if(this.isWhatsAppChannel){
              let url = this.urlAPI7

              let data = new FormData();

              this.sendDisabled = true

              let toNumber = this.message.to
              let newToNumber = toNumber

              /*if(toNumber.indexOf(this.message.pre)<0){
                newToNumber = this.message.pre+this.message.to
              }*/

              console.log('Contactando al número de teléfono')
              console.log(toNumber.indexOf(this.message.pre))
              console.log(newToNumber)

              data.append('from', this.message.from)
              data.append('to', newToNumber)
              data.append('body', this.message.finalmessage)
              

              await axios.post(url, data, {
                headers:{

                }
              })
                  .then(response => {
                    console.log(response)

                    this.sendDisabled = false

                    if(response.data.id!=null && response.data.id!=undefined && response.data.id!='undefined'){
                      this.sendedMessageOk=true
                      this.sendMessageToDB(response.data.id, null, null, null, JSON.stringify(response.data))
                      
                    }else{
                      let regs = this.smsRegs.length
                      let newreg = {'message_from':this.selectedChannel, 'message_to':this.message.pre+this.message.to, 'message':TheMessageTemplate, 'color':'amber darken-1', 'status':'Error', 'record':''+response.data.id+''}
                      this.$set(this.smsRegs, regs, newreg)
                      
                    }
                    

                  }).catch(error => {
                      this.sendDisabled = false
                      console.error(error)

                      let regs = this.smsRegs.length
                      let newreg = {'message_from':this.selectedChannel, 'message_to':this.message.pre+this.message.to, 'message':TheMessageTemplate, 'color':'red lighten-1', 'status':error.code, 'record':''+error.message+''}
                      this.$set(this.smsRegs, regs, newreg)
                      
                  })

            }else{
              let url = 'https://t160api.esmtcx.solutions/'+this.message.provider+''+this.urlAPI

              let data = new FormData();

              this.sendDisabled = true

              let toNumber = this.message.to
              let newToNumber = toNumber

              if(toNumber.indexOf(this.message.pre)<0){
                newToNumber = this.message.pre+this.message.to
              }

              console.log('Contactando al número de teléfono')
              console.log(toNumber.indexOf(this.message.pre))
              console.log(newToNumber)

              data.append('from', "channel:"+this.message.from)
              data.append('to', newToNumber)
              data.append('body', this.message.finalmessage)
              data.append('token', 'Bearer d34120be-bd82-3411-9986-5f12ea10afde')
              data.append('body', this.message.finalmessage)

              await axios.post(url, data, {
                headers:{

                }
              })
              //idMessage, from=null, to=null, message=null, record=null, iscampaign=null, t=null, i=null
                  .then(response => {
                    console.log(response)

                    this.sendDisabled = false

                    if(response.data.id!=null && response.data.id!=undefined && response.data.id!='undefined'){
                      this.sendedMessageOk=true
                      this.sendMessageToDB(response.data.id, this.message.from, newToNumber, this.message.finalmessage, JSON.stringify(response.data), '', 'SMS')
                      
                    }else{
                      let regs = this.smsRegs.length
                      let newreg = {'message_from':this.selectedChannel, 'message_to':this.message.pre+this.message.to, 'message':TheMessageTemplate, 'color':'amber darken-1', 'status':'Error', 'record':''+response.data.id+''}
                      this.$set(this.smsRegs, regs, newreg)
                      
                    }
                    

                  }).catch(error => {
                      this.sendDisabled = false
                      console.error(error)

                      let regs = this.smsRegs.length
                      let newreg = {'message_from':this.selectedChannel, 'message_to':this.message.pre+this.message.to, 'message':TheMessageTemplate, 'color':'red lighten-1', 'status':error.code, 'record':''+error.message+''}
                      this.$set(this.smsRegs, regs, newreg)
                      
                  })
            }
            
        },

        esNumeroColombiano(numero) {
          var primerDigito = numero.charAt(0);
          console.log(primerDigito)
          return primerDigito === '3';
        },

        async sendMessageBulk(){

            let url = this.urlAPI
            let i = 0
            this.sendDisabled = true
            let list = null

            if(this.csvImport){
              list = this.csv.data
            }else{
              list = this.selectedCampaingList
            }

            console.log('//Lista de envio')
            console.log(list)

            while(i<list.length){
                    try{
                          let TheMessageTemplate = ''
                          let posiciones = null
                          let variables = this.GenesysCloudCampaingListsVars
                          //let variables2 = {}
                          
                          TheMessageTemplate = this.message.body

                          if(this.messageTemplateSelected!=''){
                            //TheMessageTemplate = this.message.body.replace('[firstName]', this.GenesysCloudExternalContactSelectedVars.firstName);
                            posiciones = this.getIndices(this.message.body, '{')
                            //posiciones = this.extraerPalabrasEntreLlaves(this.message.body)
                            let e=0

                            console.log(posiciones)

                            for(e=0; e<Object.keys(this.GenesysCloudCampaingListsVars).length; e++){

                              //if(this.message.body.indexOf(' ', posiciones[e]-1)>=0){
                              
                                //variables[e] = this.message.body.substring(posiciones[e]-1, this.message.body.indexOf(' ', posiciones[e]-1))
                                TheMessageTemplate = TheMessageTemplate.replace(variables[e], list[i][this.GenesysCloudCampaingListsVars[e]])

                                TheMessageTemplate = TheMessageTemplate.replace(/[{}}]/g, "")
                              //}

                            }
                          }

                        console.log(TheMessageTemplate)


                      //TheMessageTemplate
                          console.log('///Bulk new message')
                          console.log(TheMessageTemplate)

                          let data = new FormData();
                          let a = 0

                          let proveedor = ''
                          let integracion = ''
                          let om = null
                          
                          for(a=0; a<this.channels.length; a++){
                            let channel = null
                            
                            if(this.csvImport){
                              if(list[i].SMSChannel!=null && list[i].SMSChannel!=''){
                                channel = list[i].SMSChannel
                              }else{
                                if(this.selectedChannel!=''){
                                  channel = this.selectedChannel
                                }
                              }
                              
                            }else{
                              channel = this.selectedCampaingList[i].from
                            }

                            console.log(channel)

                            if(this.channels[a].name == channel){
                              data.append('from', "channel:"+this.channels[a].id)
                              proveedor = this.channels[a].provider
                              om = this.channels[a].om
                              
                              if(this.channels[a].data0!=null && this.channels[a].data0!=''){
                                console.log(this.channels[a].data0)
                                integracion = this.channels[a].data0
                              }
                              break
                            }
                          }

                          let phone = ''
                          console.log(proveedor)
                          
                            if(proveedor!='masivapp'){
                              if(this.csvImport){
                                if(list[i].CellPhone!=null && list[i].CellPhone!=''){
                                  phone = list[i].CellPhone
                                }else if(list[i].phone!=null && list[i].phone!=''){
                                  phone = list[i].phone
                                }else if(list[i].cellPhone!=null && list[i].cellPhone!=''){
                                  phone = list[i].cellPhone
                                }else if(list[i].telefono!=null && list[i].telefono!=''){
                                  phone = list[i].telefono
                                }else if(list[i]['TELEFONO1']!=null && list[i]['TELEFONO1']!=''){
                                  phone = list[i]['TELEFONO1']
                                }else if(list[i]['TELEFONO2']!=null && list[i]['TELEFONO2']!=''){
                                  phone = list[i]['TELEFONO2']
                                }else if(list[i]['TELEFONO3']!=null && list[i]['TELEFONO3']!=''){
                                  phone = list[i]['TELEFONO3']
                                }else if(list[i]['TELEFONO4']!=null && list[i]['TELEFONO4']!=''){
                                  phone = list[i]['TELEFONO4']
                                }else if(list[i]['TELEFONO5']!=null && list[i]['TELEFONO5']!=''){
                                  phone = list[i]['TELEFONO5']
                                }else if(list[i]['TELEFONO6']!=null && list[i]['TELEFONO6']!=''){
                                  phone = list[i]['TELEFONO6']
                                }else if(list[i]['TELEFONO7']!=null && list[i]['TELEFONO7']!=''){
                                  phone = list[i]['TELEFONO7']
                                }else if(list[i]['TELEFONO8']!=null && list[i]['TELEFONO8']!=''){
                                  phone = list[i]['TELEFONO8']
                                }else if(list[i]['TELEFONO9']!=null && list[i]['TELEFONO9']!=''){
                                  phone = list[i]['TELEFONO9']
                                }else if(list[i]['TELEFONO10']!=null && list[i]['TELEFONO10']!=''){
                                  phone = list[i]['TELEFONO10']
                                }else if(list[i]['PHONE1']!=null && list[i]['PHONE1']!=''){
                                  phone = list[i]['PHONE1']
                                }else if(list[i]['PHONE2']!=null && list[i]['PHONE2']!=''){
                                  phone = list[i]['PHONE2']
                                }else if(list[i]['PHONE3']!=null && list[i]['PHONE3']!=''){
                                  phone = list[i]['PHONE3']
                                }else if(list[i]['PHONE4']!=null && list[i]['PHONE4']!=''){
                                  phone = list[i]['PHONE4']
                                }else if(list[i]['PHONE5']!=null && list[i]['PHONE5']!=''){
                                  phone = list[i]['PHONE5']
                                }else if(list[i]['PHONE6']!=null && list[i]['PHONE6']!=''){
                                  phone = list[i]['PHONE6']
                                }else if(list[i]['PHONE7']!=null && list[i]['PHONE7']!=''){
                                  phone = list[i]['PHONE7']
                                }else if(list[i]['PHONE8']!=null && list[i]['PHONE8']!=''){
                                  phone = list[i]['PHONE8']
                                }else if(list[i]['PHONE9']!=null && list[i]['PHONE9']!=''){
                                  phone = list[i]['PHONE9']
                                }else if(list[i]['PHONE10']!=null && list[i]['PHONE10']!=''){
                                  phone = list[i]['PHONE10']
                                }
                              }else{
                                phone = list[i].to
                              }
                            }else{
                              if(this.csvImport){
                                if(list[i].CellPhone!=null && list[i].CellPhone!='' && this.esNumeroColombiano(list[i].CellPhone)){
                                  phone = list[i].CellPhone
                                }else if(list[i].phone!=null && list[i].phone!='' && this.esNumeroColombiano(list[i].phone)){
                                  phone = list[i].phone
                                }else if(list[i].cellPhone!=null && list[i].cellPhone!='' && this.esNumeroColombiano(list[i].cellPhone)){
                                  phone = list[i].cellPhone
                                }else if(list[i].telefono!=null && list[i].telefono!='' && this.esNumeroColombiano(list[i].telefono)){
                                  phone = list[i].telefono
                                }else if(list[i]['TELEFONO1']!=null && list[i]['TELEFONO1']!='' && this.esNumeroColombiano(list[i]['TELEFONO1'])){
                                  console.log(list[i]['TELEFONO1']+' encontrado')
                                  phone = list[i]['TELEFONO1']
                                }else if(list[i]['TELEFONO2']!=null && list[i]['TELEFONO2']!='' && this.esNumeroColombiano(list[i]['TELEFONO2'])){
                                  console.log(list[i]['TELEFONO2']+' encontrado')
                                  phone = list[i]['TELEFONO2']
                                }else if(list[i]['TELEFONO3']!=null && list[i]['TELEFONO3']!='' && this.esNumeroColombiano(list[i]['TELEFONO3'])){
                                  console.log(list[i]['TELEFONO3']+' encontrado')
                                  phone = list[i]['TELEFONO3']
                                }else if(list[i]['TELEFONO4']!=null && list[i]['TELEFONO4']!='' && this.esNumeroColombiano(list[i]['TELEFONO4'])){
                                  console.log(list[i]['TELEFONO4']+' encontrado')
                                  phone = list[i]['TELEFONO4']
                                }else if(list[i]['TELEFONO5']!=null && list[i]['TELEFONO5']!='' && this.esNumeroColombiano(list[i]['TELEFONO5'])){
                                  console.log(list[i]['TELEFONO5']+' encontrado')
                                  phone = list[i]['TELEFONO5']
                                }else if(list[i]['TELEFONO6']!=null && list[i]['TELEFONO6']!='' && this.esNumeroColombiano(list[i]['TELEFONO6'])){
                                  console.log(list[i]['TELEFONO6']+' encontrado')
                                  phone = list[i]['TELEFONO6']
                                }else if(list[i]['TELEFONO7']!=null && list[i]['TELEFONO7']!='' && this.esNumeroColombiano(list[i]['TELEFONO7'])){
                                  console.log(list[i]['TELEFONO7']+' encontrado')
                                  phone = list[i]['TELEFONO7']
                                }else if(list[i]['TELEFONO8']!=null && list[i]['TELEFONO8']!='' && this.esNumeroColombiano(list[i]['TELEFONO8'])){
                                  console.log(list[i]['TELEFONO8']+' encontrado')
                                  phone = list[i]['TELEFONO8']
                                }else if(list[i]['TELEFONO9']!=null && list[i]['TELEFONO9']!='' && this.esNumeroColombiano(list[i]['TELEFONO9'])){
                                  console.log(list[i]['TELEFONO9']+' encontrado')
                                  phone = list[i]['TELEFONO9']
                                }else if(list[i]['TELEFONO10']!=null && list[i]['TELEFONO10']!='' && this.esNumeroColombiano(list[i]['TELEFONO10'])){
                                  console.log(list[i]['TELEFONO10']+' encontrado')
                                  phone = list[i]['TELEFONO10']
                                }else if(list[i]['PHONE1']!=null && list[i]['PHONE1']!='' && this.esNumeroColombiano(list[i]['PHONE1'])){
                                  console.log(list[i]['PHONE1']+' encontrado')
                                  phone = list[i]['PHONE1']
                                }else if(list[i]['PHONE2']!=null && list[i]['PHONE2']!='' && this.esNumeroColombiano(list[i]['PHONE2'])){
                                  console.log(list[i]['PHONE2']+' encontrado')
                                  phone = list[i]['PHONE2']
                                }else if(list[i]['PHONE3']!=null && list[i]['PHONE3']!='' && this.esNumeroColombiano(list[i]['PHONE3'])){
                                  console.log(list[i]['PHONE3']+' encontrado')
                                  phone = list[i]['PHONE3']
                                }else if(list[i]['PHONE4']!=null && list[i]['PHONE4']!='' && this.esNumeroColombiano(list[i]['PHONE4'])){
                                  console.log(list[i]['PHONE4']+' encontrado')
                                  phone = list[i]['PHONE4']
                                }else if(list[i]['PHONE5']!=null && list[i]['PHONE5']!='' && this.esNumeroColombiano(list[i]['PHONE5'])){
                                  console.log(list[i]['PHONE5']+' encontrado')
                                  phone = list[i]['PHONE5']
                                }else if(list[i]['PHONE6']!=null && list[i]['PHONE6']!='' && this.esNumeroColombiano(list[i]['PHONE6'])){
                                  console.log(list[i]['PHONE6']+' encontrado')
                                  phone = list[i]['PHONE6']
                                }else if(list[i]['PHONE7']!=null && list[i]['PHONE7']!='' && this.esNumeroColombiano(list[i]['PHONE7'])){
                                  phone = list[i]['PHONE7']
                                }else if(list[i]['PHONE8']!=null && list[i]['PHONE8']!='' && this.esNumeroColombiano(list[i]['PHONE8'])){
                                  console.log(list[i]['PHONE8']+' encontrado')
                                  phone = list[i]['PHONE8']
                                }else if(list[i]['PHONE9']!=null && list[i]['PHONE9']!='' && this.esNumeroColombiano(list[i]['PHONE9'])){
                                  console.log(list[i]['PHONE9']+' encontrado')
                                  phone = list[i]['PHONE9']
                                }else if(list[i]['PHONE10']!=null && list[i]['PHONE10']!='' && this.esNumeroColombiano(list[i]['PHONE10'])){
                                  console.log(list[i]['PHONE10']+' encontrado')
                                  phone = list[i]['PHONE10']
                                }
                              }else{
                                phone = list[i].to
                              }
                            }
                            

                          data.append('to', phone)
                          data.append('body', TheMessageTemplate)
                          data.append('token', 'Bearer d34120be-bd82-3411-9986-5f12ea10afde')
                          data.append('tokenGC', this.tokenGC)
                          data.append('om', om)
                          data.append('auth', this.channels[a].auth)

                          console.log(proveedor)
                          console.log(integracion)
                          console.log(phone)
                          console.log(this.$urlAPI+proveedor+url)

                          await axios.post(this.$urlAPI+proveedor+url, data, {
                            headers:{
                            }
                          })
                              .then(response => {
                                console.log(response)
                                //              //idMessage, from=null, to=null, message=null, record=null, iscampaign=null, t=null, i=null

                                if(proveedor=='syniverse'){

                                  if(this.csvImport){
                                    if(response.data.id!=null && response.data.id!=undefined && response.data.id!='undefined'){
                                      //let regs = this.smsRegs.length
                                      //let newreg = {'from':list[i].SMSChannel, 'to':list[i].CellPhone, 'body':this.message.body, 'color':'light-green lighten-3', 'status':'delivered', 'id':''+response.data.id+''}
                                      //this.$set(this.smsRegs, regs, newreg)
                                      this.sendedMessageOk=true
                                      this.sendMessageToDB(response.data.id, list[i].SMSChannel, phone, TheMessageTemplate, null, this.messageCampaignSelected, this.tokenGC, integracion, proveedor, 'yes')
                                      
                                    }else{
                                      this.sendedMessageError=true
                                      let regs = this.smsRegs.length
                                      let newreg = {'from':list[i].SMSChannel, 'to':phone, 'body':TheMessageTemplate, 'color':'amber darken-1', 'status':'Error', 'id':''+response.data+''}
                                      this.$set(this.smsRegs, regs, newreg)
                                      
                                      //this.sendMessageToDB(response.data.id)
                                    }
                                    
                                  }else{
                                    if(response.data.id!=null && response.data.id!=undefined && response.data.id!='undefined'){
                                      //let regs = this.smsRegs.length
                                      //let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'light-green lighten-3', 'status':'delivered', 'id':''+response.data.id+''}
                                      //this.$set(this.smsRegs, regs, newreg)
                                      this.sendedMessageOk=true
                                      this.sendMessageToDB(response.data.id, this.selectedCampaingList[i].from, this.selectedCampaingList[i].to)
                                      
                                    }else{
                                      let regs = this.smsRegs.length
                                      let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'amber darken-1', 'status':'Error', 'id':''+response.data+''}
                                      this.sendedMessageError=true
                                      this.$set(this.smsRegs, regs, newreg)
                                      
                                      //this.sendMessageToDB(response.data.id)
                                    }
                                  }

                                }else if(proveedor=='masivapp'){
                                  console.log('Entramos a Masivaap')
                                  
                                  if(this.csvImport){
                                    //console.log('L1')
                                    let data2 = response.data.data
                                    console.log(data2)
                                    if(data2!=null){
                                      //let regs = this.smsRegs.length
                                      //let newreg = {'from':list[i].SMSChannel, 'to':list[i].CellPhone, 'body':this.message.body, 'color':'light-green lighten-3', 'status':'delivered', 'id':''+response.data.id+''}
                                      //this.$set(this.smsRegs, regs, newreg)
                                      this.sendedMessageOk=true
                                      console.log('Enviando a DB:'+response.data.data.messageId+'-'+list[i].SMSChannel+'-'+phone+'-'+TheMessageTemplate+'-'+this.tokenGC+'-'+integracion)
                                      this.sendMessageToDB(response.data.data.messageId, list[i].SMSChannel, phone, TheMessageTemplate, JSON.stringify(response.data), this.messageCampaignSelected, this.tokenGC, integracion, proveedor)
                                      let data22 = JSON.parse(response.data.data2)
                                      if(data22.conversationId!=null && this.channels[a].data3=='y'){
                                        this.setGenesysInteraction(list[i]['ROBOTID'], data22.conversationId, this.messageCampaignSelected, 'Exitoso')
                                      }

                                      if(data22.conversationId!=null && this.channels[a].data1=='y'){
                                        this.setGenesysInteractionExternalTag(data22.conversationId, this.channels[a].data2)
                                      }
                                      //
                                    }else if(response.data['statusCode']==400){
                                      this.sendedMessageOk=true
                                      console.log('Enviando fallido a DB:'+response.data.statusMessage+'-'+list[i].SMSChannel+'-'+phone+'-'+TheMessageTemplate+'-'+this.tokenGC+'-'+integracion)
                                      this.sendMessageFailedToDB(response.data.statusMessage, list[i].SMSChannel, phone, TheMessageTemplate, JSON.stringify(response.data), this.messageCampaignSelected, '', integracion, proveedor)
                                    }else{
                                      this.sendedMessageError=true
                                      let regs = this.smsRegs.length
                                      let newreg = {'from':list[i].SMSChannel, 'to':phone, 'body':TheMessageTemplate, 'color':'amber darken-1', 'status':'Error', 'id':''+response.data+''}
                                      this.$set(this.smsRegs, regs, newreg)
                                      
                                      //this.sendMessageToDB(response.data.id)
                                    }
                                    
                                  }else{
                                    console.log('L2')
                                    if(response.data.data.messageId!=null && response.data.data.messageId!=undefined && response.data.data.messageId!='undefined'){
                                      //let regs = this.smsRegs.length
                                      //let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'light-green lighten-3', 'status':'delivered', 'id':''+response.data.id+''}
                                      //this.$set(this.smsRegs, regs, newreg)
                                      this.sendedMessageOk=true
                                      this.sendMessageToDB(response.data.messageId, this.selectedCampaingList[i].from, this.selectedCampaingList[i].to)
                                      
                                    }else{
                                      let regs = this.smsRegs.length
                                      let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'amber darken-1', 'status':'Error', 'id':''+response.data+''}
                                      this.sendedMessageError=true
                                      this.$set(this.smsRegs, regs, newreg)
                                      //this.sendMessageFailedToDB(response.data.data.statusMessage, list[i].SMSChannel, phone, TheMessageTemplate, JSON.stringify(response.data), this.messageCampaignSelected, '', integracion, proveedor)

                                      
                                      //this.sendMessageToDB(response.data.id)
                                    }
                                  }

                                }

                              }).catch(error => {
                                  console.error(error)

                                  let regs = this.smsRegs.length
                                  let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'red lighten-1', 'status':error.code, 'id':''+error.message+''}
                                  this.$set(this.smsRegs, regs, newreg)
                              })
                              console.log(i)

                              if (i % 150 === 0) {
                                console.log(`Esperando 30 segundos después de ${i} iteraciones`);
                                await new Promise(resolve => setTimeout(resolve, 30000));
                              }
                    }catch(e){
                                console.log(e)
                                let regs = this.smsRegs.length
                                let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'red lighten-1', 'status':e.code, 'id':''+e.message+''}
                                this.$set(this.smsRegs, regs, newreg)

                                break
                    }
                    i++
                    if(i==(this.selectedCampaingList-1)){
                        this.sendDisabled = false
                    }
                    
            }

          this.sendDisabled = false
            
        },
        //this.setGenesysInteraction(list[i]['ROBOTID'], response.data.data2.conversationId, this.messageCampaignSelected, 'Exitoso')

        async setGenesysInteraction(a,b,c,d){
          let data = new FormData
          data.append('ROBOTID', a)
          data.append('conversationId', b)
          data.append('CampaignName', c)
          data.append('tokenGC', this.tokenGC)
          data.append('ResultadoEnvio', d)

          setTimeout(() => {
            axios.post('https://t160api.esmtcx.solutions/setGenesysConversationAttributes.php', data, {
                            headers:{
                            }
                          })
                              .then(response => {
                                console.error(response)

                              }).catch(error => {
                                  console.error(error)

                              })
            }, 10000);
        },

        async setGenesysInteractionExternalTag(a,b){
          let data = new FormData
          data.append('conversationId', a)
          data.append('tokenGC', this.tokenGC)
          data.append('info', b)

          setTimeout(() => {
            axios.post('https://t160api.esmtcx.solutions/setGenesysConversationExternalTag.php', data, {
                            headers:{
                            }
                          })
                              .then(response => {
                                console.error(response)

                              }).catch(error => {
                                  console.error(error)

                              })
            }, 10000);
        },
        //WS
        async sendMessageBulk2(){

            let url = this.urlAPI8

            let data = new FormData();
            console.log('Es un mensaje digital de whatsapp: '+this.isWhatsAppChannel)

            this.sendDisabled = true

            /*let toNumber = this.message.to

            if(toNumber.indexOf(this.pre)>0){
              toNumber.replace(this.pre, '');
            }*/

            //console.log(toNumber)

            //return false

            this.sendDisabled = true
            
            let list = null
            list = this.csv.data

            console.log('//Lista de envio')
            console.log(list)

            let i=0

            while(i<list.length){

              let jsonBody = {
                'org_facebook_id':this.selectedChannelWS.code,
                'org_facebook_token':this.selectedChannelWS.auth,
                'org_facebook_to':list[i].cellPhone,
                'org_facebook_template_name':this.selectedChannelWSTemplatesSelected,
                'org_facebook_template_language': 'es_MX',
              }

              console.log(jsonBody)

              data.append('org_facebook_id', this.selectedChannelWS.code)
              data.append('org_facebook_token', this.selectedChannelWS.auth)
              data.append('org_facebook_to', list[i].cellPhone)
              data.append('org_facebook_template_name', this.selectedChannelWSTemplatesSelected)
              data.append('org_facebook_template_language', 'en_US')
              
              //console.log(data)

              

            /*data.append('from', "channel:"+this.message.from)
            data.append('to', this.selectedCampaingListTo)
            data.append('body', this.message.body)
            data.append('token', 'Bearer d34120be-bd82-3411-9986-5f12ea10afde')*/

            try{

              await axios.post(url, data, {
                headers:{
                }
              })
                .then(response => {
                  console.log(response)

                  this.sendDisabled = false
                  this.sendMessageToDB(response.data.messages[0].id, this.selectedChannelWS.type, list[i].cellPhone, JSON.stringify(response.data), JSON.stringify(response), this.messageCampaignSelected)
                  

                }).catch(error => {

                  this.sendDisabled = false
                  console.error(error)

                })

              
              }catch(e){
                console.log(e)
                //let regs = this.smsRegs.length
                //let newreg = {'from':this.selectedCampaingList[i].from, 'to':this.selectedCampaingList[i].to, 'body':this.message.body, 'color':'red lighten-1', 'status':e.code, 'id':''+e.message+''}
                //this.$set(this.smsRegs, regs, newreg)

                //break
              }
              i++
            }

            

            
        },

        async obtainOrgMessageTemplates(){
            let url = this.urlAPI6+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgMsgTemplates = response.data.data.data
                            this.getAllTemplates()
                        }else{
                            this.orgMsgTemplates = null
                        }
                        
                    }).catch(error => {
                        this.orgMsgTemplates = null
                        console.error(error)

                })
        },

        async obtainOrgCampaignList(){
          console.log('obteniendo campañas')
            let url = this.urlAPI9+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgCampaignLists = response.data.data.data
                        }else{
                            this.orgCampaignLists = null
                        }
                        
                    }).catch(error => {
                        this.orgCampaignLists = null
                        console.error(error)

                })
        },

        async loadChannels(){
            await axios.get(this.$urlAPI+'smsApp_get_organization_channels_availables.php?id_organization='+this.idOrgInDB, {
                    headers:{

                    }
                    }).then(response => {
                        console.log('Loaded SMS channels')

                        if(response.data.code=='01'){
                            console.log(response.data.data)
                            this.channels = response.data.data
                        }else{
                            console.error(response.data.message)
                        }
                    }).catch(error => {
                        console.error('Loaded SMS channels error')
                        console.error(error)
                        this.tokenActive = false

                    })
        },

        async getGenesysCloudContactLists(){

            await fetch(this.$urlAPI+'genesys_get_all_contact_lists.php?tgc='+this.tokenGC)
              .then(response => response.json())
              .then(result => {
                this.contactLists = result.entities
                console.log(this.contactLists)
                //console.log(result)
              })
              .catch(error => console.log('error', error));
        },

        async getGenesysCloudContactListSelected(e){

          this.csv.data = []
          this.csvCant = 0
          this.csv.meta = {}
          this.GenesysCloudCampaingListsVars = {}
          this.messageTemplateSelected = ''
          //this.GenesysCloudCampaingListSelected = ''

           await axios.get('https://s9.esmtcx.solutions/api/inicialize-contactlist/'+e.id, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response.data)

                        if(response.data.code=='01'){
                           
                           const task3 = setInterval(() => {
                            axios.get('https://s9.esmtcx.solutions/api/download-contactlist/'+e.id, {
                              headers:{

                              }
                            })
                            .then(response2 => {
                              console.log('Contactlist cargado correctamente')
                              console.log(response2.data)
                              this.csvImport = true
                              
                              //this.csv.push({data:[]})
                              
                              this.csv.data = response2.data
                              this.csvCant = response2.data.length
                              this.csv.meta = {'fields':e.columnNames}
                              //this.csv.meta.fields.push(e.columnNames)
                              //this.csv.meta.fields.push(e.columnNames)
                              //this.GenesysCloudCampaingListsVars = e.columnNames
                              
                              clearInterval(task3)

                            }).catch(error => {
                              console.log('Contactlist error')
                              console.log(error)
                              clearInterval(task3)
                            })
                          }, 10000);

                        }
                    }).catch(error => {
                        console.error(error)
                        //console.error(error)
    

                    })
        },

        /*watchNewMessages(){
          setInterval(() => {
            axios.get(`https://smsbrain.jakiodes.com/webhooks/45445564/index.php`).then(response => {
              console.log('Webhook')
              console.log(response)
            // console.log an error if get() method is unsuccessful
            }).catch(err => {
              console.log(err)
            })
          }, 30000);*/

          /*let vm = this
          vm.ws1 = new WebSocket("wss://smsbrain.jakiodes.com/webhooks/45445564/index.php")
          console.log('//Open websocket with 45445564')
          vm.ws1.onmessage = function (event) {
            //vm.$store.dispatch("handleStream", JSON.parse(event.data))
            console.log(event.data)

          }
          vm.ws1.onerror   = function (error) {
            console.log(error)
          }*/
        //}
    },
    mounted(){
      
      /*if(localStorage.getItem('esmtAppT160')==null){
        this.auth = false
        this.$router.push({ name: 'Home' })
      }else{*/
        let theAppLocal = JSON.parse(localStorage.getItem('esmtAppT160'))

        this.theApp.name = theAppLocal.name
        this.theApp.description = theAppLocal.description
        this.theApp.state = theAppLocal.state
        this.tokenGC = theAppLocal.t
        this.idOrgInDB = theAppLocal.client.id
        this.userID = theAppLocal.u
        this.userRoles = theAppLocal.roles
        this.auth = true

        this.getGenesysCloudContactLists()
        
        this.validarRoles()
        this.getFunctions()
        
        if(!this.isGuest){
          this.getGenesysCloudExternalContacts()
          this.getGenesysCloudCampainLists()
          
        }      
        
        //if(this.userID!=null && this.userID!=''){
          this.getAllMessages100()
        //}
        
        this.obtainOrgCampaignList()
        this.obtainOrgMessageTemplates()
        this.loadChannels()

      //}

      /*if(localStorage.getItem('tgc')!=null){
        this.tokenGC = localStorage.getItem('tgc')
        
        this.getGenesysCloudCampainLists()
      }*/
    }
}
</script>
<style scoped>

select[class="csv_uploader_map_0"]{
  border: 2px  solid #000;
}

</style>