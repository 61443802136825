<template>
  <v-app>
    <v-app-bar
      app
      style="background-color:#33383d;"
      dark
    >
      <div class="d-flex align-center" @click="reloadApp()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" fill="#ff4f1f" viewBox="0 0 16 16" aria-hidden="true"><path d="M10.883 3.333h.067c.867 0 1.6-.733 1.6-1.6 0-.933-.733-1.6-1.6-1.6-.867 0-1.6.733-1.6 1.6 0 .867.667 1.534 1.533 1.6zM10.95 1c.4 0 .667.333.667.667 0 .333-.333.667-.667.667h-.067c-.4 0-.667-.333-.667-.733.001-.248.188-.601.734-.601zM9.883 4H5.817C4.35 4 3.15 5.2 3.15 6.667s1.2 2.667 2.667 2.667h4.067c1.467 0 2.667-1.2 2.667-2.667S11.35 4 9.883 4zm0 4.2H5.817c-.867 0-1.6-.733-1.6-1.6 0-.867.733-1.6 1.6-1.6h4.067c.867 0 1.6.733 1.6 1.6a1.623 1.623 0 0 1-1.601 1.6zm0 1.933H8.15c-1.467 0-2.667 1.2-2.667 2.667s1.2 2.667 2.667 2.667h1.733c1.467 0 2.667-1.2 2.667-2.667s-1.2-2.667-2.667-2.667zm0 4.267H8.15c-.867 0-1.6-.733-1.6-1.6 0-.867.733-1.6 1.6-1.6h1.733c.867 0 1.6.733 1.6 1.6 0 .867-.733 1.6-1.6 1.6z"></path></svg>
        <v-img
          alt="T160 Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="80"
        />
      </div>

      <v-spacer></v-spacer>

      <!--<v-btn
        target="_blank"
        text
        v-if="this.$route.name=='App' || this.$route.name=='Auth'"
      >
        <span class="mr-2">Docs</span>
        <v-icon>mdi-table</v-icon>
      </v-btn>-->

      <v-btn
        @click="reloadApp()"
        target="_blank"
        text
        v-if="this.$route.name!='Token'"
      >
        <v-icon>mdi-refresh-circle</v-icon>
      </v-btn>

      <v-btn
        @click="configApp()"
        target="_blank"
        text
        v-if="this.isAdmin && (this.$route.name=='App' || this.$route.name=='Reports' || this.$route.name=='Campaigns')"
      >
        <v-icon>mdi-tune</v-icon>
      </v-btn>

      <v-btn
        @click="campaignsApp()"
        target="_blank"
        text
        v-if="this.$route.name=='App' || this.$route.name=='Reports' || this.$route.name=='Configs'"
      >
        <v-icon>mdi-human-capacity-increase</v-icon>
      </v-btn>

      <v-btn
        @click="reportsApp()"
        target="_blank"
        text
        v-if="this.isAdmin && (this.$route.name=='App' || this.$route.name=='Configs' || this.$route.name=='Campaigns')"
      >
        <v-icon>mdi-console</v-icon>
      </v-btn>

      <v-btn
        @click="goToApp()"
        target="_blank"
        text
        color="yellow"
        v-if="this.$route.name=='Configs' || this.$route.name=='Reports' ||  this.$route.name=='Campaigns'"
      >
        <v-icon>mdi-network-pos</v-icon>
      </v-btn>

      <v-btn
        @click="resetApp()"
        target="_blank"
        text
        v-if="this.$route.name=='App' || this.$route.name=='Configs' || this.$route.name=='Reports'"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <!--<v-btn
        href="#"
        text
        v-if="redo"
      >
        <span class="mr-2">Redo</span>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>-->
    </v-app-bar>

    <v-main class="genesysCloudBackColor">
      <router-view @redoU="activateRedo()" @isAdmin="adminAccess()" :tokenImplicit="accessTokenImplicit" :urlClientId="urlClientId" :urlSecretClient="urlSecretClient"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      redo:false,
      isAdmin:false,
      accessTokenImplicit:null,
      urlClientId:null,
      urlSecretClient:null,
      hash:'',
      currentUrl:''
    }
  },
  methods:{
    adminAccess(){
      this.isAdmin = true
    },

    reloadApp(){
      window.location.reload()
    },

    goToApp(){
      this.$router.push({ name: 'App' })
    },

    resetApp(){
      console.log('Complete Logout')
      localStorage.setItem('esmtAppT160', '')
      localStorage.removeItem('esmtAppT160')
      this.$router.push({ name: 'Token' })
    },

    configApp(){
      this.$router.push({ name: 'Configs' })
    },

    campaignsApp(){
      this.$router.push({ name: 'Campaigns' })
    },

    reportsApp(){
      this.$router.push({ name: 'Reports' })
    }
  },
  created() {
    this.currentUrl = window.location.href;
  },
  watch: {
    currentUrl(newUrl) {
      const urlParams = new URLSearchParams(newUrl.split('#')[1]);
      this.accessTokenImplicit = urlParams.get('access_token');

      const urlParams2 = new URLSearchParams(newUrl.split('#')[1]);
      this.urlClientId = urlParams2.get('client_id');

      const urlParams3 = new URLSearchParams(newUrl.split('#')[1]);
      this.urlSecretClient = urlParams3.get('client_secret');

      console.log(this.urlClientId, this.urlSecretClient)
    }
  },
  mounted() {
    
          /*window.addEventListener('hashchange', function() {
              this.hash = window.location.hash;
              this.tokenImplicitExist = this.hash.match(/access_token=([^&]+)/)[1];
              //this.tokenImplicitExist = access_token
              //console.log(access_token);
          });  */
  },
};
</script>

<style>
.genesysCloudBackColor2{
  background-color: #33383d;
}
.genesysCloudBackColor{
  background-color:#444a52;
}

.esmtGreenColor{
  background-color:#013c4c;
}

</style>
